/*** Look at "include(spanX)" instead of using span class names ***/

.row {
  max-width: meta-unit($max-width, 'px');
  margin: 0 auto;
  position: relative;

  [class^=span] {
    float: left;
    position: relative;
    margin: 0 (percentage($margin/$max-width));
  }

  .expand {
    margin: 0;

    &.double {
      padding-right: (percentage($margin/$max-width));
    }

    &.double + [class^=span] {
      margin-left: 0;
    }
  }

  &.vertical-centre {
    @include media-min($small+1) {
      display: flex;
      align-items: center;
    }
  }

  .text-right {
    text-align: right;
  }

  .text-centre {
    text-align: center;
  }

  .inner-row {
    margin-left: -2.6%;

    [class^=span] {
      margin-right: 0;
      margin-left: 2.6%;
    }
  }

  [class*=border-left] {
    margin-left: 0;
    padding-left: (percentage($margin/$max-width));
  }

  &.gapless {
    @include media-min($small+1) {
      padding: 0 (percentage($margin/$max-width));
    }

    [class^=span] {
      margin: 0;
    }

    .span4 {
      @include media-min($small+1) {
        width: (percentage(1/3));
      }
    }

    @include media($small) {

      [class^=span] {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  .span1 {
    width: (percentage($column/$max-width));

    &.expand {
      width: (percentage(($column+2*$margin)/$max-width));
    }
  }

  .span2 {
    width: (percentage((2*$column+2*$margin)/$max-width));

    &.expand {
      width: (percentage((2*$column+4*$margin)/$max-width));
    }
  }

  .span3 {
    width: (percentage((3*$column+4*$margin)/$max-width));

    &.expand {
      width: (percentage((3*$column+6*$margin)/$max-width));
    }
  }

  .span4 {
    width: (percentage((4*$column+6*$margin)/$max-width));

    &.expand {
      width: (percentage((4*$column+8*$margin)/$max-width));
    }
  }

  .span5 {
    width: (percentage((5*$column+8*$margin)/$max-width));

    &.expand {
      width: (percentage((5*$column+10*$margin)/$max-width));
    }
  }

  .span6 {
    width: (percentage((6*$column+10*$margin)/$max-width));

    &.expand {
      width: (percentage((6*$column+10*$margin)/$max-width));
    }
  }

  .span7 {
    width: (percentage((7*$column+12*$margin)/$max-width));

    &.expand {
      width: (percentage((7*$column+14*$margin)/$max-width));
    }
  }

  .span8 {
    width: (percentage((8*$column+14*$margin)/$max-width));

    &.expand {
      width: (percentage((8*$column+16*$margin)/$max-width));
    }
  }

  .span9 {
    width: (percentage((9*$column+16*$margin)/$max-width));

    &.expand {
      width: (percentage((9*$column+18*$margin)/$max-width));
    }
  }

  .span10 {
    width: (percentage((10*$column+18*$margin)/$max-width));

    &.expand {
      width: (percentage((10*$column+20*$margin)/$max-width));
    }
  }

  .span11 {
    width: (percentage((11*$column+20*$margin)/$max-width));

    &.expand {
      width: (percentage((11*$column+22*$margin)/$max-width));
    }
  }

  .span12 {
    width: (percentage((12*$column+22*$margin)/$max-width));

    &.expand {
      width: (percentage((12*$column+24*$margin)/$max-width));
    }
  }

  .offset1 {
    margin-left: (percentage(($column+3*$margin)/$max-width));
  }

  .offset2 {
    margin-left: (percentage((2*$column+5*$margin)/$max-width));
  }

  .offset3 {
    margin-left: (percentage((3*$column+7*$margin)/$max-width));
  }

  .offset4 {
    margin-left: (percentage((4*$column+9*$margin)/$max-width));
  }

  .offset5 {
    margin-left: (percentage((5*$column+11*$margin)/$max-width));
  }

  .offset6 {
    margin-left: (percentage((6*$column+13*$margin)/$max-width));
  }

  .offset7 {
    margin-left: (percentage((7*$column+15*$margin)/$max-width));
  }

  .offset8 {
    margin-left: (percentage((8*$column+17*$margin)/$max-width));
  }

  .offset9 {
    margin-left: (percentage((9*$column+19*$margin)/$max-width));
  }

  .offset10 {
    margin-left: (percentage((10*$column+21*$margin)/$max-width));
  }

  .offset11 {
    margin-left: (percentage((11*$column+23*$margin)/$max-width));
  }

  &:after {
    @include cf();
  }

  /* Can't decide whether this will be useful, or will cause issues */
  // @include media($small) {
  //  [class*=offset] {
  //    margin-left: (percentage($margin/$max-width));
  //  }
  // }

}

@include media($small) {

  section {

    .row {

      [class^=span] {
        width: (percentage((12*$column+22*$margin)/$max-width));
      }

      [class*=offset] {
        margin-left: (percentage($margin/$max-width));
      }
    }
  }
}