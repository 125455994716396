﻿.hidden, .selectric-hidden {
    display: none;
}
.checkout-container {
    margin-top: -2.5rem;

    .checkout-wrapper {

        @media(min-width: $small-desktop) {
            display: flex;
            flex-direction: row-reverse;
        }

        .order-summary-wrapper {
            background-color: $grey-bg;
            padding: 1rem;


            @media(min-width: $small-desktop) {
                margin-top: 3rem;
                width: 40%;
                padding: 1rem 2.5rem;
            }

            @media(min-width: $max-lg-desktop) {
                padding: 1rem 13rem 1rem 2.5rem;
                margin-top: 5rem;
            }

            .order-summary {
                color: $black;
                letter-spacing: 2.69px;
                @include font-size(14);
                font-family: 'HelveticaNeueLTStd-Md';
                text-transform: uppercase;
                text-decoration: none;

                @media(min-width: $small-desktop) {
                    display: none;
                }

                &:hover {
                    cursor: pointer;
                }

                svg {
                    height: 14px;
                    width: 14px;
                }

                .summary-total {
                    float: right;
                }
            }

            #order-summary {
                @media(min-width: $small-desktop) {
                    display: block;
                }

                .discount-codes {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    border-bottom: 1px solid $border;
                    padding-bottom: 1rem;
                    margin-bottom: 2rem;

                    & > li {
                        list-style: none;
                        padding: 0.25rem 0;
                        color: $text;
                        @include font-size(14,18);
                        text-decoration: none;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        font-family: 'HelveticaNeueLTStd-Md';

                        .basket-remove {
                            display: flex;
                            align-items: center;
                            text-decoration: none;

                            @include font-size(13);
                            text-transform: none;

                            svg {
                                height: 16px;
                                width: 16px;
                                margin: 0 10px 6px 0;
                            }
                        }
                    }
                }


                .order-summary-item-list {

                    .order-list-items {
                        list-style: none;
                        border-bottom: 1px solid $border;

                        .order-list-items-wrapper {
                            padding: 1.25rem 0;
                            display: flex;

                            .checkout-item {
                                font-family: 'HelveticaNeueLTStd-Lt';
                                display: flex;
                                margin-right: 1rem;

                                @media(min-width: $min-tablet) {
                                    margin-right: 1.75rem;
                                }

                                .checkout-product-img {
                                    width: 80px;
                                    height: 80px;
                                    min-width: 80px;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    margin-right: 1rem;

                                    @media(min-width: $min-tablet) {
                                        margin-right: 1.75rem;
                                    }
                                }

                                .checkout-product-name {
                                    @include font-size(15);
                                    color: $black;
                                }

                                .checkout-product-spec {
                                    @include font-size(14,18);
                                    color: $grey-on-white;
                                    display: flex;
                                    flex-direction: column;

                                    @media(min-width: $small-desktop) {
                                        @include font-size(16,26);
                                    }
                                }
                            }

                            .checkout-product-cost {
                                color: $text;
                                @include font-size(13);
                                letter-spacing: 2.32px;
                                flex: 1;
                                text-align: right;
                            }
                        }
                    }
                }

                .customer-code-form {
                    display: flex;
                    margin: 1.5rem 0;
                    flex-wrap: wrap;

                    input[type="text"] {
                        width: calc(100% - 100px);
                        padding-bottom: 0px;
                        /* @media(min-width: $min-tablet) and (max-width: $small-desktop) {
                            margin-left: 55%;
                        }*/
                    }

                    button {
                        height: 55px;
                        width: 100px;
                        @include font-size(16,21);
                        font-family: 'HelveticaNeueLTStd-Md';
                        border: 0;
                        -webkit-appearance: none;
                        padding-right: 0;
                    }

                    .field-validation-error {
                        display: block;
                        width: 100%;
                        @include font-size(14,20);
                    }
                }

                .checkout-subtotal-items {

                    & > div {
                        display: flex;
                        text-transform: uppercase;
                        color: $text;
                        letter-spacing: 2.32px;
                        @include font-size(13);
                        justify-content: flex-end;
                        padding: 0.25rem 0;

                        .checkout-item-number {
                            min-width: 100px;
                            text-align: right;
                        }
                    }

                    .checkout-total-item {
                        text-transform: none;
                        @include font-size(20,30);
                        letter-spacing: 0;
                        color: $black;
                        padding-top: 0.5rem;
                    }
                }
            }
        }

        .checkout-information {

            @media(min-width: $small-desktop) {
                width: 70%;
                padding: 0 2rem;
            }

            @media(min-width: $max-lg-desktop) {
                width: 47%;
                padding: 0 2rem;
            }

            .checkout-info {
                margin: 2rem 0;

                form {

                    @media(min-width: $max-desktop) {
                        .no-flex {
                            max-width: 45%;
                        }
                    }

                    .flex-fields {
                        @media(min-width: $max-desktop) {
                            display: flex;
                            justify-content: space-between;

                            & > div {
                                width: 45%;
                            }

                            .selectric {
                                padding-bottom: 5px;
                            }
                        }
                    }

                    @media(min-width: $max-tablet) {
                        margin-left: 0;
                    }

                    input, textarea, select, .selectric-wrapper {
                        margin-bottom: 2rem;
                    }



                    textarea {
                        border: 1px solid $border;
                        margin-top: 1.5rem;
                    }


                    h2 {
                        font-family: 'HelveticaNeueLTStd-Lt';
                        @include font-size(30,40);
                    }

                    .contact-info {

                        h3 {
                            @include font-size(20,30);
                        }

                        .checkbox-wrapper {
                            display: flex;
                            margin-bottom: 2rem;

                            input {
                                max-width: 13px;
                                margin-right: 0.5rem;
                            }

                            label {
                                text-transform: none;
                                @include font-size(14,18);
                                letter-spacing: 0;
                            }
                        }

                        #shipping-info {
                            display: none;
                        }
                    }
                }

                .shipping-method-radio {
                    margin: 3rem 0;

                    ul {
                        margin-top: 2.5rem;

                        & > li {
                            list-style: none;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid $border;
                            padding: 2rem 0 1.75rem;

                            label {
                                display: flex;
                                @include font-size(14,18);
                                text-transform: none;
                                margin-bottom: 0;


                                input[type="radio"] {
                                    width: 15px;
                                    margin: 0 1rem 0.15rem 0;
                                    transform: scale(1.25);
                                }
                            }

                            > span {
                                @include font-size(13);
                                flex-shrink: 0;
                                margin-left: 5px;

                                img {
                                    height: 28px;
                                    width: auto;
                                }
                            }
                        }
                    }
                }

                .general-info {
                    p {
                        strong {
                            @include font-size(20,30);
                            font-weight: normal;
                            margin: 2rem 0;
                            display: block;
                            color: $black;
                        }
                    }
                }

                .payment-title {
                    margin-top: 3rem;
                }

                .review-contact-info {
                    margin: 2rem 0;

                    @media(min-width: $min-tablet) {
                        display: flex;
                    }

                    & > div {
                        margin: 0.5rem 0;
                        display: flex;

                        @media(min-width: $min-tablet) {
                            flex: 1;
                        }

                        label {
                            text-transform: uppercase;
                            @include font-size(13,23);
                            letter-spacing: 2px;
                            display: block;
                            min-width: 5rem;
                        }
                    }
                }

                .review-addresses {
                    @media(min-width: $min-tablet) {
                        display: flex;

                        & > div {
                            flex: 1;
                        }
                    }

                    .review-address {
                        display: flex;
                        flex-direction: column;
                        margin: 2rem 0;
                    }
                }

                .checkbox-wrapper {
                    display: flex;
                    margin-bottom: 2rem;

                    input {
                        max-width: 13px;
                        margin-right: 0.5rem;
                    }

                    label {
                        text-transform: none;
                        @include font-size(12,16);
                    }
                }

                .order-print {

                    a {
                        text-decoration: none;
                        color: $black;
                        font-family: 'HelveticaNeueLTStd-Md';
                        @include font-size(16,21);
                        display: flex;

                        svg {
                            height: 16px;
                            width: 16px;
                            margin-right: 1.25rem;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .form-cta-btn {

                @media(min-width: $max-tablet) {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                }

                button {
                    width: 100%;
                    margin-bottom: 1rem;
                    height: 56px;

                    @media(min-width: $max-tablet) {
                        width: 45%;
                    }

                    @media(min-width: $max-desktop) {
                        width: 30%;
                    }

                    svg {
                        width: 19px;
                        height: 19px;
                        fill: currentColor;
                        margin-right: 0.75rem;
                        margin-left: 0;
                        margin-top: -5px;
                    }
                }

                a {
                    height: 56px;
                    width: 100%;

                    @media(min-width: $max-tablet) {
                        width: auto;
                    }



                    svg {
                        margin-right: 0.5rem;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
