﻿.sub-category-wrapper {
    .sub-category-product {
        .product-listing {
            .listing-video {
                position: relative;

                svg {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.1);
                    }
                }
            }

            .modal-window {
                position: fixed;
                background-color: rgba(0, 0, 0, 0.4);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 999;
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
                transition: all 0.3s;

                &:target {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: auto;
                }

                & > div {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 4em 2em 1.5em 2em;
                    background: #ffffff;
                }

                iframe {
                    height: 276px;
                }
            }

            .modal-close {
                color: #000;
                @include font-size(16,21);
                position: absolute;
                right: 2rem;
                text-align: right;
                top: 2rem;
                width: 70px;
                text-decoration: none;
                font-family: 'HelveticaNeueLTStd-Md';

                span {
                    margin-right: 0.25rem;
                }

                svg {
                    vertical-align: middle;
                }
            }
        }
    }
}

#InspirationGalleryListing {

    .sub-category-wrapper {

        .sub-category-product {
            margin-bottom: 20px;

            @media(min-width: $max-tablet) {
                flex: 0 0 50%;
                max-width: 50%;

                .product-listing {
                    .modal-window {
                        & > div {
                            width: 95%;
                        }

                        iframe {
                            height: 560px;
                        }
                    }
                }
            }

            @media(min-width: $small-desktop) {
                flex: 0 0 33%;
                max-width: 33%;

                .product-listing {
                    .modal-window {
                        & > div {
                            width: 50%;
                        }

                        iframe {
                            height: 648px;
                        }
                    }
                }
            }

            @media(min-width: $max-lg-desktop) {
                flex: 0 0 25%;
                max-width: 25%;
            }


            .product-listing {
                .sub-category-content {
                    h3 {
                        margin: 0;

                        @media(min-width: $max-lg-desktop) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
  