.continent-container {
      margin-bottom: 35px;

  margin-bottom: 35px;

  h1 {
    text-align: center;
  }

  .continent-wrapper {

    .continent {
  
      margin-bottom: 50px;
  
      .country-list {
        list-style-type: none;

        li {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;
        }
      }
    }
  
    

    @media(min-width: $min-tablet) {

      background-image: url("../images/region-selection-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 650px;

      .continent {
        .country-list {
          display: flex;
          flex-wrap: wrap;
      
          li {
            max-width: 25%;
            flex: 0 0 25%;
          }
        }
      }
    }

    @media(min-width: $large) {

      .continent-lists {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;

        .continent {
          width: 40%;
          margin-right: 50px;
        }
      }
    }

    @media(min-width: $mega) {

      .continent-lists {
        .continent {
          width: 45%;
        }
      }
    }
  }
}
