.product-details-container {
    display: flex;
    flex-direction: column;
    margin: 60px 0;

    .product-image {
        flex: 0 0 100%;
        max-width: calc(100% + 60px);
        justify-content: center;
        margin: 0 -30px;

        .image-link {
            cursor: zoom-in;
        }

        img {
            width: 100%;
        }

        @media(min-width: $max-tablet) {
            max-width: 100%;
            margin: 0;
        }
    }

    .product-gallery-images {
        display: flex;
        gap: 10px;
        margin-top: 10px;

        .image-link {
            position: relative;
            flex-basis: calc(25% - 7px);
            cursor: zoom-in;

            .zoom-link {
                margin: 0;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .product-gallery-image-wrapper {
            padding-top: 100%;
            background-position: 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .product-details {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        h1 {
            margin-bottom: 0;
        }

        .product-description {
            ul {
                padding-left: 20px;

                > li {
                    margin-bottom: 5px;
                }
            }
        }

        .product-buttons {
            display: flex;
            flex-direction: column;

            .button {
                flex-grow: 1;
                margin-top: 10px;
            }
        }
    }
}

.specification-container {
  position: relative;
  margin-bottom: 60px;

  .container {
    position: relative;
  }

  h2 {
    text-align: center;
    padding-top: 40px;
  }

  .specification-wrapper {
    background-color: $white;

    .specification-dropdown {
      text-align: center;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .specifcation-listing {
        border-bottom: none;
      }

      a {
        align-self: center;
        span {
          text-transform: uppercase;
        }

        .close-dropdown {
          display: none;
        }

        &.dropdown-active {

          .open-dropdown {
            display: none;
          }

          .close-dropdown {
            display: initial;
          }

          svg {
            transform: rotate(180deg);
          }
          
        }
      }
    }

    .spec-dropdown-full-container,
    .specifcation-listing {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }

    .spec-dropdown-full-container {
      .spec-dropdown-full {
        flex-grow: 1;
        border-bottom: 1px solid $table-grey;
        border-top: 1px solid $table-grey;

      }
    }

    .specifcation-listing {
      border-bottom: 1px solid $table-grey;

      &.dropdown-listing {
        border-bottom: none;
      }

      transition: max-height .5s ease-out;
      max-height: 600px;
      overflow: hidden;

      &.dropdown-listing {
        max-height: 0;
        padding: 0;
        transition: max-height .5s ease-out;

      }

      .specification {
        text-align: center;
        padding-top: 20px;

        &:last-of-type {
          padding-bottom: 20px;
        }

        .specification-heading {
          text-transform: uppercase;
          @include font-size(13);
        }

        .specification-icon {
          display: none;
          padding-top: 20px;

          .icon {
            width: 56px;
            height: 56px;
          }
        }

        .specification-text {
          color: $grey-on-white;

          .dimensions-text {
            display: flex;
            justify-content: center;

            span {
              margin-right: 5px;
            }
          }

          &.download-text {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .product-download {
             
              display: flex;
              width: 50%;
              padding: 0 10px 1rem 0;
              text-align: left;
              align-self: flex-start;
              align-items: flex-start;

              svg {
                width: 20px;
                height: 20px;
              }

              &:hover {

                svg {
                  margin-left: 0;
                  margin-right: 10px;
                }
              }

              &:nth-of-type(2n) {
                padding: 0 0 1rem 10px;
              }

              span {
                text-transform: unset;
              }
            }
          }

        }
      }
    }
  }

  .delivery-information {
    margin: 3rem 0;
    max-width: 600px;
    text-align: center;
  }
}

.product-cta-container {
  position: fixed;
  background-color: #171717;
  bottom: 0;
  width: 100%;
  z-index: 10;

  max-height: 0;
  transition: max-height 1.5s ease-in;

  &.product-cta-active {
    max-height: 130px;
    transition: max-height 1.5s ease-out;
  }

  .product-cta {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px 20px 20px;

    p {
      color: $grey-on-black;
      margin: 0;
    }

    .product-links {
      display: flex;
      padding-top: 15px;

      a {
        &:first-child {
          padding-right: 15px;
        }
      }
    }
  }
}

.colour-tool-code {
  padding: 20px;

  .icon-ral-paintbrush {
    width: 13px;
    height: 22px;
    margin-bottom: -3px;
  }

  &.desktop {
    display: none;
  }
}

.colour-tool-disclaimer {
  font-family: 'HelveticaNeueLTStd-It';
  @include font-size(12); 
  margin-top: 20px;
}


.colour-tool-container {
  background-color: $grey-bg;
  padding: 60px 0;
  text-align: center;
  margin-bottom: 60px;

  .colour-tool-text {
    max-width: 600px;
    margin: 0 auto 30px;
  }

  .colour-tool-content {

    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column-reverse;

    .colour-tool-panel-wrapper {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .mobile-panel-background {
        .colour-tool-code {
          background-color: $light-grey;
          padding: 5px;
        }
      }

      .colour-tool-panel {
        background-color: $black;
        padding: 25px 20px;
        width: 100%;
        // margin-top: 30%;

        .icon-wallpaperlogo {
          width: 85px;
          height: 19px;
          margin-bottom: -3px;
        }

        .colour-tool-button {
          display: flex;
          justify-content: center;

          button {
            width: 100%;
          }

        }

        .sub-text-wrapper {

          

          .sub-text {
            @include font-size(14);
          }
        }


        p, h2 {
          color: $white;
        }

        h2 {
          margin-bottom: 0.75rem;
        }

      }
    }
  }
}

.buy-online-cta {
  text-align: center;
  margin: 0 -30px 60px;

  @media(min-width: $max-tablet) {
     
        margin: 0 0 60px;
    }

  h2, p {
    color: $white;
  }

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 0;

  .buy-online-cta-button {
    display: flex;
    justify-content: center;
  }
}

.colour-tool-button {
            padding-bottom: 20px;
          }

@media(min-width: $min-desktop) {

  .colour-tool-container {
  
    .colour-tool-content {
      background-size: cover;

      flex-direction: column;
  
      .colour-tool-panel-wrapper {

        flex-direction: row;

        .mobile-panel-background {
          display: none;
        }
  
        .colour-tool-panel {
          max-width: 365px;
          margin-top: 60px;
          margin-right: 60px;
          padding: 30px 45px;

          

          .sub-text-wrapper {

            display: block;
          }
        }
      }
  
      .colour-tool-code {
        text-align: left;
        display: block;
      }
    }
  
    .colour-tool-disclaimer {
      font-style: italic;
      @include font-size(12);
      margin-top: 20px;
    }
  
  }

  .product-cta-container {

    &.product-cta-active {
      max-height: 100px;
    }

    .product-cta {
      justify-content: space-between;
      flex-direction: row;

      .product-links {
        padding-top: 0;
      }
    }
  }

  .specification-container {

    .specification-wrapper {

      .mobile-dropdown {
        h3, .specification-dropdown-button {
          display: none;
        }
      }

      .spec-dropdown-full-container,
      .specifcation-listing {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .spec-dropdown-full-container {
        .spec-dropdown-full {

          flex: 0 0 100%;

          align-self: baseline;

          &:first-child {
            margin-right: 10px;
            border-bottom: 1px solid $table-grey;
          }

          &:last-child {
            margin-left: 10px;
          }
        }
      }

      .specifcation-listing {
        .specification {
          padding: 10px 40px 10px 40px;
        }

        &.desktop-listing {
          max-height: 100%;
          padding-top: 30px;
          padding-bottom: 30px;
          width: 100%;
  
          &.dropdown-listing {
            padding-top: 30px;
            padding-bottom: 0px;
            max-height: 100%;
            transition: none;
          }
          .specification {
            flex: 0 0 33%;
            max-width: 33%;
  
            .specification-icon {
  
              display: block;
  
              .icon {
                width: 56px;
                height: 56px;
              }
            }
          }
        }
      }
    }
  }

  .product-details-container {
  
    .product-details {
  
      .product-buttons {
        flex-wrap: wrap;
        flex-direction: row;
  
        .button {

          width: 48%;
          margin-right: 10px;

        }
      }
    }
  }
}

@media(min-width: $small-desktop) {
  .product-details-container {

    flex-direction: row;
  
    .product-image {
      flex: 0 0 48%;
      max-width: 48%;
      margin-right: 35px;
    }
  
    .product-details {
      margin-left: 35px;
      margin-top: 0;
  
      .product-buttons {
  
        .button {

          width: auto;
          margin-right: 10px;

        }
      }
    }
  }
}


.colour-intro {
 

  .button.view-finishes-cta {
    svg {
      width: 35px;
      height: 35px;
      margin-bottom: 0;
    }
  }
}