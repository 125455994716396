﻿.basket-container {

    .mob-header {

        text-transform: uppercase;
        color: #333;
        @include font-size(13);
        letter-spacing: 2.32px;
        font-family: HelveticaNeueLTStd-Lt;
        width: 7rem;
        float: left;
        clear: left;

        @media(min-width: $small-desktop) {
            display: none;
        }

    }



    h1 {
        letter-spacing: 0.98px;
    }

    .basket-empty-wrapper {        

        @media(min-width: $min-desktop) {
            width: 66%;
            margin: 4rem auto;

            .basket-empty-cta {
                width: 50%;
               
                margin-top: 2rem;
            }
        }
    }

    form {
        margin-left: 0;

        input {
            margin-bottom: 0px;
            padding-bottom: 0.25rem;
        }

        .shopping-cart {
            display: block;
            width: 100%;

            @media(min-width: $small-desktop) {
                display: table;
            }

            .options-title {

                @media(min-width: $small-desktop) {
                    display: none;
                }
            }

            .text-right {
                text-align: right;

                @media(max-width: $small-desktop) {
                    text-align: left;
                }
            }

            .text-left {
                text-align: left;
            }

            .text-center {
                text-align: center;
            }

            thead {
                display: none;
                
                 @media(min-width: $small-desktop) {
                    display: table-row-group;
                }

                tr:first-child {
                    background-color: transparent;
                    font-weight: normal;
                }


                th {
                    text-transform: uppercase;
                    color: $black-2;
                    border-bottom: 1px solid $border;
                    padding-bottom: 10px;
                    @include font-size(13);
                    letter-spacing: 2.32px;

                    .pl-6 {
                        padding-left: 1.5rem;
                    }
                }
            }

            tbody {

                @media(max-width: $small-desktop) {
                    display: table-row-group; 
                }


                @media(max-width: $small-desktop) {
                    td[data-title]:before {
                        color: $black-2 !important;
                        @include font-size(13);
                        content: attr(data-title);
                        float: left;
                        white-space: pre-wrap;
                        letter-spacing: 2.32px;
                        display: block;
                        text-transform: uppercase;
                        max-width: 7rem;
                        width: 7rem;
                        font-weight: normal !important;
                        font-family: 'HelveticaNeueLTStd-Md';
                        margin-right: 0;
                    }

                    .basket-title[data-title]:before {
                        content: none;
                    }

                    td {
                        width: 100% !important;
                        display: block !important;

                         @media(min-width: $small-desktop) {
                            width: auto  !important;
                            display: table-cell  !important;
                        }
                    }
                }



                tr {
                    background-color: transparent;
                    padding: 2rem 0;

                    @media(max-width: $small-desktop) {
                        display: block;
                    }

                    &.basket-items {
                        border-bottom: 1px solid $border;
                    }
                }

                td {
                    @include font-size(14,20);
                    
                    padding: 1rem 15px 1rem 0;
                    border: none;

                    @media(min-width: $small-desktop) {
                       padding: 2rem 15px 2.25rem 0;
                    }


                    &.basket-price, &.basket-total {
                        color: $black-2;
                        @include font-size(13);
                        font-family: 'HelveticaNeueLTStd-Md';
                        letter-spacing: 2.32px;

                        @media(min-width: $max-tablet) {
                            @include font-size(18,28);
                        }

                        @media(min-width: $small-desktop) {
                            text-align: center;
                            vertical-align: middle;
                            letter-spacing: 0;
                        }
                    }

                    &.basket-total {
                        padding-right: 0;
                        text-align: right;

                        @media(max-width: $small-desktop) {
                            text-align: left;
                        }
                    }

                    &.basket-qty {
                        display: flex;

                        input {
                            width: 25%;
                            max-width: 90px;
                        }

                       

                            @media(min-width: $small-desktop) {
                                text-align: center;
                                vertical-align: middle;
                                display: table-cell;

                                input {
                               margin: 0 auto;
                                width: 50%;
                            }   

                            }

                                                 

                        
                    }

                    &.basket-option-sm {

                        @include font-size(16,26);
                        color: $grey-on-white;

                        p {
                            padding-left: 7rem;
                        }


                        @media(min-width: $small-desktop) {
                            display: none;
                        }
                    }

                    .items-start {
                        align-items: flex-start;
                    }


                    .flex {
                        display: flex;

                        .basket-remove {
                            color: $black;
                            @include font-size(16,21);
                            font-family: 'HelveticaNeueLTStd-Md';
                            display: flex;
                            align-items: center;
                            text-decoration: none;

                            svg {
                                height: 16px;
                                width: 16px;
                                margin: 0 10px 6px 0;
                            }
                        }

                        .flex-shrink-0 {
                            width: 80px;
                            height: 80px;
                            min-width: 80px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                            margin-right: 1.75rem;

                            @media(min-width: $small-desktop) {
                                width: 170px;
                                height: 169px;
                            }
                        }

                        .shopping-cart__product-name {
                            display: block;
                            @include font-size(22,32);
                            font-family: 'HelveticaNeueLTStd-Lt';
                            margin-bottom: 10px;
                            text-decoration: none;

                            @media(min-width: $small-desktop) {
                                @include font-size(26,36);
                            }
                        }

                        .basket-product-detail {
                            text-transform: uppercase;
                            @include font-size(13);
                            letter-spacing: 2.32px;
                            color: $brown-grey;

                            &.basket-option {
                                display: none;

                                @media(min-width: $small-desktop) {
                                    display: block;
                                }
                            }
                        }

                        .shopping-cart__product-description {
                            @include font-size(16,26);
                            color: $black-2;
                            margin: 0;
                            font-family: 'HelveticaNeueLTStd-Lt';
                            margin-bottom: 1rem;
                        }

                        .shopping-cart__remove {
                            text-decoration: underline;
                            margin-top: .75rem;
                            display: inline-block;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    .shopping-cart__quantity-input {
                        text-align: center;
                        color: $brown-grey;
                        @include font-size(16,26);

                        @media(max-width: $small-desktop) {
                            padding: 0;
                        }
                    }
                }
            }

            tfoot {

                @media(max-width: $small-desktop) {
                    display: block;
                }

                & tr, td {
                    padding: 0;
                    background-color: transparent;
                    border: none;

                    @media(max-width: $small-desktop) {
                        display: block;
                    }
                }



                & > tr {


                    @media(max-width: $small-desktop) {
                        display: flex;
                        align-items: end;
                    }
                }

                & > tr > td:not(.reset-width):first-child {
                    width: 7rem;

                    @media(min-width: $small-desktop) {
                        width: initial;
                    }
                }

                .basket-vat {
                    text-transform: uppercase;
                    color: $text;
                    @include font-size(13);
                    letter-spacing: 2.32px;
                    padding: 2rem 0 0 ;
                }




                .shopping-cart__subtotal-row {
                    td {
                        color: $black;
                        @include font-size(20,22);
                        font-family: 'HelveticaNeueLTStd-Md';
                        padding: 1rem 0 0;
                    }

                    td:first-child {
                        text-transform: uppercase;
                        color: $text;
                        @include font-size(13);
                        letter-spacing: 2.32px;
                        font-family: 'HelveticaNeueLTStd-Lt';

                        @media(min-width: $small-desktop) {
                            font-family: 'HelveticaNeueLTStd-Md';
                            text-transform: none;
                            @include font-size(20,30);
                            color: $black;
                        }
                    }
                }

                .discount-text {

                    td {
                        padding: 1rem 15px 1rem 0;
                        color: $grey-on-white;
                        @include font-size(16,26);

                        @media(min-width: $small-desktop) {
                            padding: 1.25rem 0;
                        }
                    }
                }

                .shopping-cart__cta-row {


                    @media(max-width: $small-desktop) {
                        display: block;
                    }

                    td {
                        padding-right: 0;
                        width: 100%;

                        @media(max-width: $small-desktop) {
                            display: block;
                        }
                    }

                    .shopping-cart__cta-row-inner {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        @media(min-width: $small-desktop) {
                            flex-direction: initial;
                        }

                        a.underline {
                            order: 2;
                            margin: 2rem auto 1rem auto;

                            @media(min-width: $small-desktop) {
                                margin: 1rem 0;
                                order: 1;
                            }

                            svg {
                                margin-right: 10px;
                                margin-bottom: 4px;
                            }

                            span:after {
                                right: 0;
                                left: initial;
                            }

                            &:hover {

                                svg {
                                    margin-left: 10px;
                                }
                            }
                        }

                        .cta-row-inner {
                            display: flex;
                            order: 1;
                            flex-direction: column;

                            @media(min-width: $small-desktop) {
                                flex-direction: initial;
                                order: 2;
                            }

                            button {
                                margin: 1rem 0;

                                @media(min-width: $small-desktop) {
                                    margin: 0;
                                    margin-right: 1.75rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
