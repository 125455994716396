.selling-points {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .point {

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $border;
    padding: 40px 0;
    flex: 100%;

    @media(min-width: $min-desktop) {
      width: 33%;
      flex: 1;
      padding: 0 40px;  
      border-bottom: 0;
      border-left: 1px solid $border;   

      &:first-child {
        border-left: 0;
        padding-left: 0;
      }

      &:last-child {       
        padding-right: 0;
      }
    }

    @media(min-width: $small-desktop) {
      padding: 0 65px; 
    }

    .point-icon {
      margin-bottom: 30px;
      max-height: 38px;
      width: auto;
    }
    
    .title {
      text-align: center;
      color: $black;
      margin-bottom: 15px;
    }

    p {
      text-align: center;
      margin-bottom: 20px;
    }

    .ctas {
      display: flex;
      justify-content: center;
      flex-direction: column;

      a {
        color: $black;       
       
        margin: 10px auto 0;    

        
      }
    }
      
  }

}

.testimonials-slider {
  background: $black;
  color: $white;
  text-align: center;

  &.container.full {
    padding: 30px;
  }

  .title {
    @include font-size(13);
    text-transform: uppercase;
    color: $grey-on-black;
    margin: 30px 0;     
  }

  .testimonials {
    position: relative;
    text-align: center;
    padding-bottom: 30px;

    .slick-dots {
      
      margin-top: 40px;


      li {

        button {
          background: 0;
          border: 1px solid white;
          width: 14px;
          height: 14px;
        }

        &.slick-active {
          button {
            background: white;            
          }
        }

        
      }
    }
  }
  .testimonial {
    color: $white;
    text-align: center;

    p {
       @include font-size(20);
       margin-bottom: 20px;
    }

    .name {
      color: $grey-on-black
    }
  }
}


.find-dealer {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;

  &:before {
    content: '';
    background: $overlay;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

   &.container {
    padding: 30px;
  }

  .contents {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    text-align: center;

    h2 {
      color: white;
      margin-bottom: 20px;
    }

    p {

      a {
        color: white;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .button {
      margin-top: 15px;
    }

  }
 

 

  @media(min-width: $max-tablet) {

    .contents {   
      padding: 30px 0;
    }
  }


}

.inspiration {
  
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;

  &:before {
    content: '';
    background: $overlay;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .contents {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 45px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    text-align: center;

    h2 {
      color: white;
      margin-bottom: 20px;
    }

    p {

      a {
        color: white;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .button {
      margin-top: 15px;

      &.white-border {

        &:hover {
          border: 1px solid $white;
        }
      }
    }

    .secondary-img {
      display: none;
    }

  }

  .img {
    display: none;
  }

  @media(min-width: $min-desktop) {

    background-image: none !important;
    display: flex;

    &:before {
      display: none;
    }

    .img {
      display: block;
      width: 50%;
      z-index: 2;
      position: relative;
      flex: 1;
      margin-top: 60px;
    }

    .video-container {
       position: relative;
        padding-bottom: 100%;
        height: 0;
        overflow: hidden;

    }

    .contents {   
      padding: 0 30px 0 0;
      z-index: 1;
      position: relative;
      align-items: flex-start;
      flex: 1;
      padding: 0;

      .secondary-img {
        display: block;
        width: calc( 100% + 80px);
        margin-right: -80px;
        margin-top: 80px;
      }

      h2 {
        color: black;
      }

      p {
        color: $text;
        width: 100%;
        max-width: 330px;
        text-align: left;
      }

      .button.white-border {
        background-color: $white;
        color: $black;
        border: 1px solid $black;
        transition: all .2s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: $black;
          transition: all .2s;
          z-index: -1;
        }

        &:hover {
          color: $white;

          .icon {

            &.icon-close-black {
              fill: $white;
            }
          }

          &:before {
            width: 100%;
          }
        }
      }
    }
  }


}



.newsletter-signup {

  display: flex;
  flex-wrap: wrap;

  .intro, 
  .form {
    width: 100%;


  }

  .umbraco-forms-submitmessage {
    background: url(../images/icons/sprite-icon-tick-white.svg) no-repeat 0 0;
    background-size: 20px;
  }

  form {
    margin: 0;
    background: $black-bg;

    .col-md-12 .umbraco-forms-field {
      width: 100%;
      margin-bottom: 0px;
    }

    label {
      color: white;
      letter-spacing: 2px;
    }

    input {
      color: white;
      background: transparent;
      
      ::-webkit-input-placeholder,
      ::-moz-placeholder,
      :-ms-input-placeholder ,
      :-moz-placeholder { /* Firefox 18- */
        color: $grey-on-black;
      }
    }

    input[type="submit"]  {

      margin: 40px 0 0;
      background-image: url(../images/icons/sprite-icon-cta-arrow-white.svg);
        background-position: right 30px center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        padding-right: 65px;
        transition: background-color .2s ease;

      &:hover {
        background-color: white;
        background-image: url(../images/icons/sprite-icon-cta-arrow.svg);
      }
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px $black-bg inset !important;
      /*use inset box-shadow to cover background-color*/
      -webkit-text-fill-color: white !important;
      /*use text fill color to cover font color*/
    }

    .checkbox {

      label {
        color: $grey-button-hover;
        margin-bottom: 1.5rem;
      }

      .checkmark {       
        background-color: transparent;
        border: 1px solid $form-border;
        margin-top: -1px;

        &:after {              
          background-color: $form-border;
          
        }
      }      
    }
  }

  .umbraco-forms-submitmessage{
    color: $grey-1;
  }


  .intro {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    position: relative;

    &:before {
      content: '';
      background: $overlay;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
       padding: 45px 30px;

      h2 {
        color: white;
      }

      p {
        color: $grey-on-overlay;
      }

    }

  }

  .form {
    background: $black-bg;

    .container {
      padding: 35px 30px;
    }
  }


  @media(min-width: $max-tablet) {

    .intro, 
    .form {
      width: 50%;
      display:flex;
      align-items: center;
     
     .container {
        max-width: 400px;  
      }
     
    }  

    .form {
      .container {
        max-width: 400px;  

        form {
          display:flex;
          align-items: flex-start;
          flex-direction: column;
        }      
      }
    }

    
  
  }
    @media(min-width: $max-desktop) {

    .intro, 
    .form {
      width: 50%;
      display:flex;

      .container {
        max-width: 445px;        
      }
     
    }
    .intro {
      justify-content: flex-end;

      .container {
        margin-right: 100px;
      }
    }

    .form {
      justify-content: flex-start;

      .container {
        margin-left: 100px;
      }
    }
  
  }

}


.featured-products {

  h3 {
    text-align: center;
    margin-bottom: 35px;
  }

  .products {



    .product {
      display: flex;
      flex-direction: column;

      .img {
        width: 100%;
        height: 287px;
        overflow: hidden;
        position: relative;

        img {
          height: 100%;
          width: auto;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          max-width: none;
        }
      }

      .contents {
        padding: 30px 0;

        a.underline {
          justify-content: left;
        }

        h3 {
          margin-bottom: 10px;
        }

        .cat {
          @include font-size(14);
          color: $brown-grey;
          margin-bottom: 15px;
        }

        .price {
          @include font-size(13);
          text-transform: uppercase;
          color: $brown-grey;
          margin-top: 10px;
        }
      }
    }

    @media(min-width: $max-tablet) {

      display: flex;


      .product {
        margin-left: 20px;

        .img {
          height: 222px;
        }
        

        &:first-child {
          margin-left: 0;
        }

        .contents {
          padding-right: 40px;
        }
      }


    }

    @media(min-width: $small-desktop) {

      .product {

        .img {
          height: 332px;
        }
      }

    }

  }

}


.image-text-grid {
  position: relative;
  padding-bottom: 40px;

  @media(min-width: $max-tablet) {
    padding: 0px 0;
  }

  @media(min-width: $max-desktop) {
    padding: 60px 0;
  }

  &:before {
    content: '';
    background-color: $grey-bg;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media(min-width: $max-tablet) {
       bottom: 0;
    }

    @media(min-width: $max-desktop) {
       top: 60px;
       bottom: 120px;
    }
  }

  .row {
    text-decoration: none;
    padding-bottom: 35px;

    @media(min-width: $max-tablet) {
      padding-bottom: 0;
      display: flex;

    }

    @media(min-width: $max-desktop) {
          padding-bottom: 35px;
    }

    .img {
      margin: 0 0 0 -30px;

      .caption-wrapper {
          margin: 10px 0 0;
      }

      @media(min-width: $max-tablet) {

        order: 2;
        margin: 80px 0 0 0;
        width: 50%;
        text-align: end;
        padding-left: 30px;


        .caption-wrapper {
          margin: -30px 0 0 -50px;
        }

      }

      @media(min-width: $max-desktop) {
        margin: -60px 0 0 0;
        padding-left: 60px;
      }
    }

    .copy {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      .sub-header {
        @include font-size(13,13);
        text-transform: uppercase;
        color: $brown-grey;
        display: flex;
        align-items: center;
        margin: 20px 0 10px;
        letter-spacing: 2px;

        &:before {
          content: '';
          height: 1px;
          width: 20px;
          background: $brown-grey;
          margin: 0 10px 3px 0;
        }
      }

      a {
        font-family: 'HelveticaNeueLTStd-Md';
        align-self: flex-start;
      }

      @media(min-width: $max-tablet) {
        padding: 40px 30px 40px 0;
        width: 50%;
      }

      @media(min-width: $max-desktop) {
        padding: 60px 30px 60px 0;
      }


    }

    &:nth-child(even) {
      padding-bottom: 0;

      .img {
        margin: 0 -30px 0 0;

        @media(min-width: $max-tablet) {

          order: 1;
          margin: 80px 0 0;
          text-align: start;
          padding: 0 30px 0 0;

        }

        @media(min-width: $max-desktop) {
          margin: 0;
          padding: 0 60px 0 0;
        }
      }

      @media(min-width: $max-tablet) {

        .copy {
          order: 2;
          padding: 60px 0;
        }

      }


       @media(min-width: $max-desktop) {

        .copy {
          order: 2;
          padding: 40px 0;
        }

      }

      
    }

  }
}
