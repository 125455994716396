/*** replace with project specific fonts ***/

@font-face {
  font-family: 'HelveticaNeueLTStd-Md';
  src: url('/common/css/fonts/HelveticaNeueLTStd-Md/font.woff2') format('woff2'), url('/common/css/fonts/HelveticaNeueLTStd-Md/font.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueLTStd-Bd';
  src: url('/common/css/fonts/HelveticaNeueLTStd-Bd/font.woff2') format('woff2'), url('/common/css/fonts/HelveticaNeueLTStd-Bd/font.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueLTStd-Lt';
  src: url('/common/css/fonts/HelveticaNeueLTStd-Lt/font.woff2') format('woff2'), url('/common/css/fonts/HelveticaNeueLTStd-Lt/font.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueLTStd-It';
  src: url('/common/css/fonts/HelveticaNeueLTStd-It/font.woff2') format('woff2'), url('/common/css/fonts/HelveticaNeueLTStd-It/font.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeueLTStd-Roman';
  src: url('/common/css/fonts/HelveticaNeueLTStd-Roman/font.woff2') format('woff2'), url('/common/css/fonts/HelveticaNeueLTStd-Roman/font.woff') format('woff');
  font-display: swap;
}

body {
  font-family: 'HelveticaNeueLTStd-Lt', sans-serif;
  color: $text;
}

html {
  font-size: 16px;
  font-weight: 100;
  line-height: 1.65rem;
  -webkit-font-smoothing: antialiased;
}

.bold {
  font-family: 'HelveticaNeueLTStd-Bd';
}

.spaced {
  letter-spacing: 2.6px;
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  color: black;
}

h1 {
  @include font-size(30);
  margin-bottom: .5rem;
}

h2, .h2 {
  @include font-size(24);
  margin-bottom: 1.5rem;
}

h3, .h3, .umbraco-forms-submitmessage {
  @include font-size(22);
}

h4, .h4 {
  @include font-size(20);
}

h5, .h5 {
    @include font-size(18);
}

@media(min-width: $max-tablet) {

  h1 {
    @include font-size(50);
    margin-bottom: .5rem;
  }

  h2, .h2 {
    @include font-size(32);
    margin-bottom: 1.5rem;
  }

  h3, .h3, .umbraco-forms-submitmessage {
    @include font-size(26);
  }

  h4, .h4 {
    @include font-size(20);
  }

}

