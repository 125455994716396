﻿#Listings .container.hotel-project, #InspirationGalleryListing .container.inspiration-gallery {
    margin-top: 4rem;
    padding: 0 20px;

    @media(min-width: 600px) {
        padding: 0 40px;
    }

    @media(min-width: 1220px) {
        padding: 0 20px;
    }
}

.product-listing {
    .location {
        @include font-size(14);
        color: #707070;
        display: flex;
        align-items: center;

        span {
            margin: 0.25rem 0 0 0.25rem;
        }
    }

    .description {
        color: #707070;
        @include font-size(13);
        text-transform: uppercase;
        letter-spacing: 2.23px;
        margin-top: 1.25rem;
    }
}

