.gallery-bg {
 
  &:before {
    background-color: $grey-bg;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .gallery-container {
    position: relative;
    width: 100%;

    .pad-30 {
      padding: 0 30px 30px; 
    }

  }

  @media (min-width: $max-tablet) {
    &:before {
      background-color: $grey-bg;
      content: '';
      position: absolute;
      top: 120px;
    }
  }
}

.gallery {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.slick-initialized {
    opacity: 1;
  }

  .slide {
    &:focus {
      outline: 0;
    }

    .slide-image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .image-wrapper {
        height: 260px;

        @media (min-width: $max-tablet) {
          height: 530px;
        }
      }

      .zoom-link {
        margin-top: -50px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        
      }

    }
  }
}




/* DOTS Paging */
.slick-dots {
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 13px;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 14px;
      height: 14px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 1px solid $black-1;
      border-radius: 100%;
      outline: none;
    }

    &.slick-active {
      button {
        background-color: $black-1;
      }
    }
  }

  @include media($medium) {
    bottom: 80px;
  }

  @include media($tiny) {
    bottom: 35px;
  }
}
