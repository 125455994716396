$selectric-main-color:      #DDD !default;                                              // Color used in border
$selectric-secondary-color: #BBB !default;                                              // Color used in button
$selectric-text-color:      #000 !default;                                              // Color used in label
$selectric-bg-color:        #F8F8F8 !default;                                           // Background color
$selectric-btn-bg-color:    #F8f8f8 !default;                                           // Button background color
$selectric-height:          40px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-border-radius:   0px !default;                                               // Border radius
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height
$selectric-font-size:       16px !default;                                              // Font size

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
    position: relative;
    line-height: normal;
    border: 0;
    border-bottom: 1px solid $form-border;
    padding: 10px 0;
    background: transparent;

    &:focus, &:active {
      border-bottom: 2px solid $form-border;
      background: transparent;
    }

  

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: $selectric-inner-height;
    height: $selectric-inner-height;
    line-height: $selectric-inner-height;
    background-color: $selectric-btn-bg-color;
    color: $selectric-secondary-color;
    text-align: center;
    font: 0/0 a;
    *font: 20px/#{$selectric-inner-height} Lucida Sans Unicode, Arial Unicode MS, Arial;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: $selectric-secondary-color;
      border-bottom: none;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 16px;
       width: 14px;
    height: 14px;
    transition: all .2s ease-in-out;
    transform: translateY(-50%);
    

    &.-rotate180 {
      transform: translateY(-50%) rotate(270deg);
    }
  }

  

  &:focus,
  &.has-value {
    border-bottom: 2px solid $form-border;
    background: transparent;
    color: $text;
  }

 
}

.selectric-focus .selectric {
  border-bottom: 2px solid $form-border;
}

.selectric-hover .selectric {
  

  

  .button {
    color: darken($selectric-secondary-color, 10%);

    &:after {
      border-top-color: darken($selectric-secondary-color, 10%);
    }
  }
}

.selectric-open {
  z-index: 90;

  .selectric {
   
    color: $text;

    .label {
     color: $text;
    }

    .icon {
      
    }
  }

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: calc(100% + 11px);
  left: 0;
  background: $white;
  z-index: -1;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: $selectric-font-size;
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: flex;
    padding: 12px 19px 10px 15px;
    cursor: pointer;
    flex-flow: row nowrap;
    align-items: center;
    height: 46px;
    width: 100%;
    position: relative;

    span {
      @include font-size(16);
      
      color: #ccc;
      height: 100%;
    }

    .icon-tick {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 17px;
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease-in-out;
    }

    .icon-arrow-right {
      margin-left: auto;
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease-in-out;
      width: 9px;
      height: 18px;
    }

    &.selected {
      background: $light-grey;
      
      span {
        
      }

      .icon-tick {
        opacity: 1;
        visibility: visible;
      }

      .icon-arrow-right {
        opacity: 0;
        visibility: hidden;
       
      }
    }

    &.highlighted {
      background: $white;
      
      span {
        
      }
    }

    &:hover {
      background: $light-grey;
      
      span {
       
      }

      .icon-arrow-right {
        opacity: 1;
        visibility: visible;
        
      }
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
