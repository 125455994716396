﻿@charset 'utf-8';

@import '../config/core';  // Font declarations

@import '../vendor/slick/slick';  // Slick slider carousel styling

section {
  position: relative;
}

.content {
  padding: 50px 0; 
}

table {

  border: none;
  
  thead {

    display: none;

    tr:first-child {
      background-color: $black;
      font-weight: bold;

      td {
        color: $white;
      }
    }
  }

  tr {
    td {
      padding: 15px;
      border: 1px solid $table-grey;
      border-bottom: none;
    }

    &:nth-of-type(2n) {
      background-color: $grey-bg;
    }

  }

  tbody {

    td {
      display: block;
      width: 100% !important;
    }

    td[data-title]:before {
      color: $text !important;
      @include font-size(16);
      content: attr(data-title);
      float: left;
      white-space: pre-wrap;
      text-align: bottom;
      display: block;
      font-weight: bold !important;
      margin-right: 10px;
    }
  }


  @media(min-width: $min-desktop) {
    thead {
      display: table-row-group;
    }
  
    tr {

      &:nth-of-type(2n) {
        background-color: $grey-bg;
      }

      td {
        padding: 15px;
        border: none;
      }
  
    }

    tbody {

      td {
        display: table-cell;
        width: auto !important;
      }
  
      td[data-title]:before {
        display: none;
      }
    }

  }
}

.standard-hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 240px;
  text-align: center;
  position: relative;
  padding: 30px 0; 

  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, .5);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .standard-hero-content {
    z-index: 0;
    position: 
    
    h1 {
      font-weight: 500;
    }

    h1, p {
      color: $white;
    }
  }

  &.no-background-hero {

    text-align: initial;
    height: auto;
    padding: 30px 0 0;
    min-height: 0;

    &:before {
      display: none;
    }

    .standard-hero-content {

      border-bottom: 1px solid $light-grey;
      margin: 30px 30px 0;
      padding: 0 0 30px;

      @media(min-width: $max-tablet) {
          padding: 0 0 50px;
        }

      h1, p {
        color: $black;

        @media(min-width: $small-desktop) {
          max-width: 70%;
        }
      }

      p {
        color: $black-2;
        margin-top: 10px;
      }
    }
  }

  @media(min-width: $min-tablet) {
    height: 400px;
  }
}

.content-listing-container {

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;


  .content-listing-wrapper {
    .content-listing {
  
      background-color: $grey-bg;
      margin-bottom: 20px;
  
      text-align: center;
      height: 100%;

      .content-listing-image {
        height: 220px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;

        @media(min-width: $small-desktop) {
          height: 280px;
        }
      }
  
      .content-listing-text {
        padding: 40px 30px;

        @media(min-width: $max-tablet) {
            padding: 40px 60px;

        }

        
      }
    }
  }


  @media(min-width: $max-tablet) {

    flex-direction: row;

    .content-listing-wrapper {

      margin-bottom: 0px;
      flex: 0 0 50%;

      &:nth-of-type(2n + 1) {
        padding-right: 10px;
      }
  
      &:nth-of-type(2n) {
        padding-left: 10px;
      }
    }
  }
}

.product-images {

  .product-image-next-btn, .product-image-prev-btn {
    position: absolute;
    z-index: 5;
    right: 0;
    margin-top: 110px;
    margin-right: 25px;
    background: transparent;
    outline: none;
    width: 60px;

    svg {
      width: 45px;
      height: 45px;
    }

    @media(min-width: $min-tablet) {
      display: none;
    }

  }

  .product-image-prev-btn {

    left:0;

    svg {
      transform: rotate(180deg);
    }
  }

  .product-image-container {
  
    display: flex;
    flex-wrap: nowrap;
  
    .slick-list {
      padding: 0px !important;
      padding-right: 20px !important;
    }
  
    .product-image {
        
      display: flex;
      padding-right: 10px;
      flex: 0 0 100%;
      max-width: 100%;
  
      img {
        display: none;
      }

      .image-link {
        width: 100%;
        cursor: zoom-in;
      }
  
      .product-image-wrapper {
        height: 260px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        width: 100%;

        .zoom-link {
          color: $white;
        }
      }
  
    }
  
    @media(min-width: $min-tablet) {
  
      flex-wrap: wrap;
  
      .product-image {
  
        padding: 10px;
  
        .product-image-wrapper {
          height: 430px;
          width: 100%;
    
          img {
            display: inherit;
          }  
        }
  
    
        &.product-100 {
          flex: 0 0 100%;
          max-width: 100%;
        }
    
        &.product-70 {
          flex: 0 0 58.3%;
          max-width: 58.3%;
        }
    
        &.product-30 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
      }
    }
  
  }
}

.zoom-link {
  position: absolute;
  margin-bottom: 25px;
  margin-left: 30px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  align-self: flex-end;
  outline: none;

  &.single-zoom {
    margin-bottom: 0;
    margin-top: -45px;
  }

  .icon-zoom {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-bottom: 4px;

    symbol#sprite-icon-zoom path {
      fill: $black;
    }

  }

}

.faqs {

  .faq {

    border-bottom: 1px solid $border;

    .accordion {
      width: 100%;
      padding: 25px 0 16px;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      text-align: left;


      .accordion-minus {
        display: none;
      }

      .accordion-plus svg,
      .accordion-minus svg{
        width: 16px;
        height: 16px;
      }

      &.active {
        .accordion-minus {
          display: block;
        }

        .accordion-plus {
          display: none;
        }
      }

    }
  
    .panel {
      max-height: 0;
      overflow: hidden;
      background-color: $white;
      transition: max-height 0.2s ease-out;
    }
  }
}


.standard-content {
  ul, ol {
    padding-left: 15px;

    li {
      padding-bottom: 10px;
    }
  }
}

.image-caption-container {
  display: flex;
  justify-content: center;

  .media-wrapper {
    padding: 0px;
    text-align: center;
    margin: 30px 0;
    max-width: 945px;

    flex-grow: 1;
  
    .lightbox-youtube {
        position: relative;
        display: block;

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 25%;
                max-width: 136px;
            }
        }

    }

    .image-caption-zoom {

      opacity: 0;
      transition: opacity 0.2s ease-out;

      .zoom-link {
        margin-top: 20px;
      }
    }

    &:hover {
      .image-caption-zoom {
        opacity: 1;
        transition: opacity 0.2s ease-out;
      }
    }

  }
}

.caption-wrapper {
  display: flex;
  align-items: center;
  width: auto;

  &.video-caption {
    position: initial;
    padding: 0;
  }

  .caption {
    margin-bottom: 0;
    font-style: italic;
    @include font-size(12);
  }

  .caption-line {
    display: none;
  }
}

.common-heading {

  display: flex;
  align-items: center;
  padding-bottom: 20px;
  letter-spacing: 2px;

  &.center-header {
    justify-content: center;
  }

  color: $form-border;
  text-transform: uppercase;
  @include font-size(13);

  .heading-line {
    display: inline-block;
    height: 1px;
    width: 20px;
    background-color: $form-border;
    margin-right: 10px;
  }
}

.download-panel-wrapper {

  .download-panel-content {

    display: flex;
    flex-direction: column;
      margin: 0 -30px;
      width: calc( 100% + 60px);

      @media(min-width: $max-tablet) {

        margin: 0;
        width: 100%;
        flex-direction: row;
      }
 

    .download-panel-image {
      height: 260px;
      background-position:  center;
      background-repeat: no-repeat;
      background-size: cover;

      @media(min-width: $max-tablet) {
        height: 390px;
        width: 50%;
        order: 2;
        z-index: 2;
      }
    }

    .download-panel-text-wrapper {

      max-width: 100%;
      background-color: $grey-bg;
      padding: 40px;
      display: flex;
      align-items: center;

       @media(min-width: $max-tablet) {
        
        width: 50%;       
        order: 1;
        margin-top: 60px;
        z-index: 1;
        position: relative;
        padding: 50px;

        &:after {
          content: '';
          position: absolute;
          right: -50%;
          width: 50%;
          top: 0;
          bottom: 0;
          background-color: $grey-bg;
        }
      }

      @media(min-width: $small-desktop) {
          padding: 70px 90px;
      }


      .download-heading {

        display: flex;
        align-items: center;
        padding-bottom: 20px;

        color: $form-border;
        text-transform: uppercase;
        @include font-size(13);

        .heading-line {
          display: inline-block;
          height: 1px;
          width: 20px;
          background-color: $form-border;
          margin-right: 10px;
        }
      }
     
      .download-panel-link a {
       
        color: $black;
        margin-top: 15px;
      }
    }

    
  }


  
}

@media(min-width: $tiny) {
  .image-caption-container {
    .media-wrapper {
      .video-inner {
        iframe {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}

@media(min-width: $min-desktop) {

  .image-caption-container {
    .media-wrapper {
      
  
      .video-inner {
        iframe {
          width: 100%;
          height: 570px;
        }
      }
    }
  }

  
  .caption-wrapper {
    position: absolute;
    margin-top: -45px;
    margin-left: -15px;

    &.video-caption {
      margin-top: 0;
      margin-left: 0;
    }
  
    .caption-line {
      display: inline-block;
      height: 1px;
      width: 70px;
      background-color: $grey-on-white;
      margin-right: 10px;
    }
  }
}
.product-images-container {
    &:before {
        content: '';
        background-color: #f5f5f5;
        position: absolute;
        z-index: -9;
        top: 4rem;
        bottom: 4rem;
        left: 0;
        right: 0;
    }
}