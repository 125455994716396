.select-wrapper {

  label {
    text-transform: uppercase;
    @include font-size(13);
    color: $text;
    padding-right: 10px;
  }

  .js-Dropdown {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  
  .js-Dropdown-title {
    background: $white;
    border: 1px solid $black;
    border-radius: 0;
    box-sizing: border-box;
    cursor: pointer;
    height: 58px;
    position: relative;
    text-align: left;
    width: 100%;
    font-family: 'HelveticaNeueLTStd-Md';
    @include font-size(14);
    padding-top: 5px;
  
    &:before {
      border-color: $white transparent transparent transparent;
      border-style: solid;
      border-width: 5px;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 27px;
      top: 45%;
      width: 0;
      z-index: 2;
    }
  
    &:after {
      border-color: $black transparent transparent transparent;
      border-style: solid;
      border-width: 7px;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 25px;
      top: 45%;
      width: 0;
    }
  
  }

  &.form-select {
    .js-Dropdown-title {
      &:before {
        right: 7px;
      }
    
      &:after {
        right: 5px;
      }
    
    }
  }
  
  .js-Dropdown-list {
    background: $white;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    border-bottom: 1px solid $black;
    box-sizing: border-box;
    display: none;
    height: 0;
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    transition: .2s linear;
    width: 100%;
    z-index: 10;
    @include font-size(14);
  
    &.is-open {
      display: block;
      height: auto;
      opacity: 1;
    }
  
    li {
      cursor: pointer;
      padding: .75em 1em;
  
      &:hover,
      &.is-selected {
        background-color: $grey-button-hover;
      }
    }
  }

  &.listing-select {
    .js-Dropdown {
      width: 85px;
    }

    .js-Dropdown-title {

      background: transparent;
      padding-left: 1.2rem;
    
      &:before {
        border-color: $grey-bg transparent transparent transparent;
        top: 45%;
      }

      &:after {
        top: 45%;
      }
    }
  }

  &.form-select {
    .js-Dropdown-title {

      @include font-size(16);

      background: transparent;
      border: 0;
      border-bottom: 1px solid $black;
      color: $form-border;

      &:before {
        border-color: $grey-bg transparent transparent transparent;
      }
    }
  }

}


.listing-select {
    display: flex;
    align-items: center;

    label {
      letter-spacing: 2px;
    }

  .selectric {
    border: 1px solid black;
    padding: 18px 35px 12px 20px;


  }

  .selectric-items {
      top: 100%;

      li {
        padding: 10px;
        justify-content: center;
      }
    }

}