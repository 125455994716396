.hero {
	margin-top: 110px;
	position: relative;	
	display: flex;
	overflow: hidden;
	color: white;
	padding: 70px 30px ;
	align-items: center;
	background-position: center center;
    background-size: cover;

	@media(min-width: $max-tablet) {
		padding: 70px 30px 120px;
	  }

	
	@media(min-width: $small-desktop) {
		height: 510px;
		padding: 120px 30px 170px;
	}

	@media(min-width: $max-desktop) {
		margin-top: 138px;
	}

	h1 {
	  @include font-size(37,47);
	  color: white;

	  @media(min-width: $max-tablet) {
		@include font-size(50,60);
	  }
	}

	.video-background {
	  position: absolute;
	  top: 50%;
	  left: 0;
	  padding-top: 56.25%;
	  width: 100%;
	  transform: translateY(-50%);
	  transition: 2s opacity ease;
	  opacity: 1; 
	  z-index: 0;
	  display: none;

	  @media(min-width: $small-desktop) {
		display: block;
	  }
	}

	.video-foreground,
	.video-background iframe {
	  position: absolute;
	  top: 0; 
	  left: 0;
	  width: 100%;
	  height: 100%;
	  pointer-events: none; 
	}

	&:before {
		content:'';
		background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%);
		background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%);
		background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;

	} 

	.container {
		position: relative;
		z-index: 2;
		max-width: 680px;
		text-align: center;
		padding: 0;

		.cta {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			flex-direction: column;

			@media(min-width: $max-tablet) {
				flex-direction: row;
			}


			a {
				margin: 10px 0;
				flex: 1;

				@media(min-width: $max-tablet) {
				  flex: initial;
				  margin: 0 10px;
			    }
			}
		}
	}

}

.intro-box {
	background: white;
	padding: 40px 30px;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;

	@media(min-width: $max-tablet) {
		max-width: 90%;
		margin: -50px auto 15px;
		padding: 50px ;
	 }

	 @media(min-width: $small-desktop) {		
		padding: 50px 120px;
	  }

	.intro {
		@include font-size(20,30);
	    text-align: center;
		width: 100%;
		text-align: center;
		max-width: 830px;
		margin: 0 auto;

		p {
			margin-bottom: 0;
		}  
	  

	  @media(min-width: $max-tablet) {
		@include font-size(30,40);
		
	  }

	  

	}

	.button {
		align-self: center;
		margin-top: 20px;
	}

}