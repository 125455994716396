.compare-table {

  .ctas {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;

    a.underline {
      align-self: center;
      margin-bottom: 20px;
    }

    @media (min-width: 768px) {
         flex-direction: row;

        a.underline {
         align-self: flex-start;
          margin-bottom: 0;
        }
    
    }

    .print {
      svg {
        margin: 0 10px 0 0;
      }
    }

    .back {
      svg {
        transform: rotate(180deg);
        margin: 0 10px 0 0;
      }

      &:hover {
        svg {
          margin-left: 0;
        }
      }
    }
  }

  .table-wrapper {
    overflow-x: auto;
    margin: 0 -30px;

    @media (min-width: 768px) {
      margin: 0 -30px 0 0;
    }

    @media (min-width: 980px) {
      margin: 0;
    }
  }

	table {

		thead {
      display: table-header-group;
			tr {
				&:first-child {
					background-color: white;
				}

				th {
					border: 1px solid $table-grey;
          background-repeat: no-repeat;
          background-size: cover;
          width: 190px;
          min-width: 150px;
          height: 138px;
          display: table-cell;

           @media (min-width: 768px) {
              min-width: 190px;
            }

          .img-wrapper {
            width: 100%;
            height: 138px;
            display: block;
            overflow: hidden;

            img {
             object-fit: cover;
             min-height: 100%;
            }
          }
          

					&:first-child {
						border-top: none;
						border-left: none;
            min-width: 100px;

            @media (min-width: 768px) {
              min-width: 150px;
            }
            
					}




				}
			}
		}

		

		tr {
			td {
				border: 1px solid $table-grey;
        text-align: center;
        padding: 20px 15px 15px;
        display: table-cell;

				&:first-child {					
					border-left: none;
          text-transform: uppercase;
          text-align: left;
          font-family: 'HelveticaNeueLTStd-Roman';
          @include font-size(13);
          letter-spacing: 2px;
				}

        a.underline:hover svg {
          margin-left: 10px;
        }

        .remove {
          margin: 0 auto;

          svg {
            margin-right: 10px;
            margin-left: 0;
          }

          &:hover svg {
            margin-left: 0;
          }
        }
			}
		}
	}

	
}

.product-comparison-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid $table-grey;  
  padding: 15px 0;
  z-index: 99;

  .container {
    display: flex;
    flex-direction: column;    
   

    @media (min-width: 768px) {
        align-items: center;
         padding: 0 0 0 30px;
    }

    @media (min-width: 980px) {
      flex-direction: row;     
      justify-content:  space-between;
      
      padding: 0 30px;
    }
  }

  .prod-compare {
    display: flex;
    align-items: center;
    flex-direction: column;


    @media (min-width: $small-desktop) {
      flex-direction: row;
      flex-wrap: wrap;
    }

  }

  

  h5 {
    @include font-size(13);
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0px;
    text-align: center;

    @media (min-width: 980px) {
      text-align: left;
      }
  }

  .ctas {
    display: flex;
    justify-content: center;
  }

  .product-comparison {
    display: flex;
    overflow-x: auto;

    margin-bottom: 10px;

    .comparison {
      position: relative;
      padding-top: 5px;

      .img-wrapper {
        width: 55px;
        height: 54px;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 5px;

        @media (min-width: 768px) {
          width: 68px;
          height: 64px;
        }
      }

      .remove-comparison {
        position: absolute;
        top: 0;
        left: -2px;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .button {
    margin: 0 5px;

   @media (max-width: 979px) {
        background: white;
        border-color: transparent !important;
        color: $text;
        padding: 10px;
        margin: 0;

        &:before {
          display: none;
        }
   }

   svg {
      margin-right: 10px;
   }

   }

}



 @media print { 

  .compare-table table tr td {
    padding: 10px;
  }

  .compare-table table thead tr th,  {
    width: 140px;
    min-width: 140px;
  }


  .compare-table table thead tr th:first-child {
    width: 100px;
    min-width: 100px;
  }


 }