/*** BUTTON Styles ***/

button {
  border: 0;
  outline: none;
}

a {
  &.button,
  .button {
    text-decoration: none;
    text-align: center;
  }
}

.button {
    @include font-size(14,14);
    font-family: 'HelveticaNeueLTStd-Md', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px 15px;
    background-color: $black;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2.6px;
    outline: none;

    &.icon-left {
        svg {
            margin-right: 10px;
            letter-spacing: 2.6px;
        }
    }


    &.back-btn {
        display: inline-flex;
    }

    .icon {
        width: 16px;
        height: 18px;
        margin-bottom: 4px;


        &.icon-close-black {
            margin-right: 10px;
        }

        &.icon-cta-arrow {
            margin-left: 10px;
        }
    }

    &.view-finishes-cta {
        display: inline-flex;

        .icon {

            @media(max-width: $min-tablet) {
                width: 35px;
                height: 37px;
            }
        }
    }

    &.button-black {
        background-color: $black;
        color: $white;
        border: 1px solid $black;
        transition: all .2s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        svg {
            fill: $white;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $white;
            transition: all .2s;
            z-index: -1;
        }

        &.no-animate {
            &:before {
                content: none;
            }
        }

        &:hover,
        a:hover & {
            color: $black;

            &.no-animate {
                color: $black;
            }

            .icon {
                fill: $black;
            }

            &:before {
                width: 100%;
            }
        }

        &.outline-black {
            border: 1px solid $black;
            background: transparent;
            color: $black;

            svg {
                fill: $black;
                margin-left: 10px;
            }

            &:before {
                background-color: $black;
            }

            &:hover,
            a:hover & {
                color: $white;

                .icon {
                    fill: $white;
                }
            }
        }
    }


    &.button-white {
        background-color: $white;
        color: $black;
        border: 1px solid $black;
        transition: all .2s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &.clear {
            background-color: transparent;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $black;
            transition: all .2s;
            z-index: -1;
        }

        &.no-animate {
            &:before {
                content: none;
            }
        }

        &:hover,
        a:hover & {
            color: $white;

            &.no-animate {
                color: $black;
            }

            .icon {
                fill: $white;
            }

            &:before {
                width: 100%;
            }
        }

        &.outline-white {
            border: 1px solid $white;
            background: transparent;
            color: $white;

            svg {
                fill: $white;
                margin-left: 10px;
            }

            &:before {
                background-color: $white;
            }

            &:hover,
            a:hover & {
                color: $black;

                .icon {
                    fill: $black;
                }
            }
        }
    }



    &.white-grey {
        background-color: $white;
        color: $black;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $grey-button-hover;
            z-index: -1;
            transition: all .2s;
        }

        &:hover,
        a:hover & {
            &:after {
                width: 100%;
            }
        }
    }

    &.black-grey {
        background-color: $black;
        color: $white;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $dark-grey-button-hover;
            z-index: -1;
            transition: all .2s;
        }

        &:hover,
        a:hover & {
            &:after {
                width: 100%;
            }
        }
    }

    &.white-border {
        background: none;
        color: $white;
        border: 1px solid $white;
        transition: all .2s;
        position: relative;
        overflow: hidden;
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $white;
            transition: all .2s;
            z-index: -1;
        }

        &:hover,
        a:hover & {
            color: $black;
            border: 1px solid $white;

            .icon {

                &.icon-close-black {
                    fill: $white;
                }
            }

            &:before {
                width: 100%;
            }
        }
    }

    &.button-noborder {
        border: none;
    }
}



.template-hotelprojectfinder, .template-inspirationgallery {
  .underline svg {
    align-self: flex-start;
    margin-top: 9px;

  }
}

.underline.external svg {
    margin-bottom: 10px;

  }