﻿.simple-header {
  .lower-nav {
    border-bottom: 1px solid $border;
  }
}


.site-header {
    background-color: $white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    transition: top .2s ease-out;



    ul {
        li {
            list-style-type: none;
        }
    }

    .basket-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0px;

        .basket {
            background-color: $grey-bg;
            position: fixed;
            height: 100%;
            // top: 69px;
            width: 100%;
            padding: 20px;
            display: none;
            left: 0;

            .basket-error{
                color: #dd1515;
                @include font-size(14);
            }

            .basket-items {
                max-height: 350px;
                overflow-y: auto;
                margin-bottom: 10px;
            }

            .basket-item {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $border;
                padding: 10px 0;

                .basket-item-wrapper {
                    display: flex;
                    align-items: center;

                    &.basket-item-content {
                        width: calc(100% - 105px);

                        @media(min-width: $max-tablet) {
                            width: 235px;
                          }

                        .basket-item-details {
                            .basket-item-name {
                                @include font-size(15,20);
                                font-family: 'HelveticaNeueLTStd-Md';
                            }

                            .basket-item-remove a {
                                @include font-size(14);
                                text-decoration: none;
                                font-family: 'HelveticaNeueLTStd-Md';
                                color: $black;
                                display: flex;
                                align-items: center;

                                .icon-remove {
                                    width: 16px;
                                    height: 16px;
                                    margin: 0 10px 6px 0;
                                }
                            }
                        }

                        .basket-item-image {
                            width: 50px;
                            height: 50px;
                            min-width: 50px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                            margin-right: 15px;
                        }
                    }

                    &.basket-item-pricing {

                       
                            width: 100px;
                         

                        @include font-size(14);

                        .basket-item-quantity {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .basket-controls {
                .basket-total {
                    @include font-size(20);
                    text-align: right;
                    font-family: 'HelveticaNeueLTStd-Md';

                    span {
                        margin-right: 20px;
                    }
                }

                .basket-buttons {
                    display: flex;
                    margin-top: 15px;

                    .basket-close {
                        flex-grow: 2;
                        margin-right: 10px;
                        padding: 20px 25px 15px;
                        background-color: transparent;
                    }

                    .basket-button {
                        flex-grow: 2;
                        padding: 20px 25px 15px;

                        &:before {
                           
                            background-color: $grey-bg;
                            
                        }

                        .icon-shopping-bag-white {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            .basket-empty-wrapper {
                padding: 1.5rem;

                h4 {
                    text-align: center;
                    padding-bottom: 1rem;
                    font-family: 'HelveticaNeueLTStd-Md';
                }
            }
        }

        @media(min-width: $min-desktop) {
            .basket {
                padding: 40px;
            }
        }

        @media(min-width: $huge) {
            padding: 0 40px;

            .basket {
                top: 138px;
                width: 400px;
                padding: 20px;
                height: auto;
                left: auto;
            }
        }
    }

    &.compact {
        border-bottom: 1px solid $light-grey;
        
        .basket-wrapper {
            .basket {
                top: 98px;
            }
        }
    }


    .site-header-wrapper {
        padding: 0px;

        .upper-nav {
            border-bottom: 1px solid $light-grey;
            display: flex;
            height: 40px;
            align-items: stretch;
            padding: 0;
            color: $grey-on-white;
            @include font-size(13,15);

            .upper-links {
                display: none;
            }

            .geo-settings {
                display: flex;
                justify-content: space-evenly;
                width: 100%;

                .geo-setting {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px 10px 0;

                    &:first-of-type {
                        border-right: 1px solid $border;
                    }

                    &.language-filter {
                        padding: 3px 5px 0;

                        @media(min-width: $max-tablet) {
                            position: relative;
                        }

                        span {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            svg {
                                width: 14px;
                                height: 14px;
                                margin-left: 3px;
                            }
                        }

                        &.language-active {
                            background-color: $grey-bg;

                            span {
                                font-weight: bold;
                                color: $black-2;

                                svg {
                                    transform: rotate(180deg);
                                }
                            }

                            &:last-of-type {
                                padding: 3px 5px 0;
                            }

                            .language-dropdown {
                                display: flex;
                            }
                        }

                        .language-dropdown {
                            position: absolute;
                            background-color: $grey-bg;
                            display: none;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            flex-direction: column;
                            @include font-size(12);
                            top: 39px;
                            padding: 20px;
                            z-index: 5;
                            height: 245px;
                            width: 100%;
                            left: 0;

                            &.language-filter-active {
                                display: flex;
                                z-index: 1000;
                            }

                            .country-list {

                                .continent {
                                    text-transform: uppercase;
                                    color: $black-2;
                                    font-family: 'HelveticaNeueLTStd-Md';
                                    @include font-size(11);

                                    @media(min-width: 321px){
                                        @include font-size(14);
                                    }
                                }

                                ul {
                                    margin: 0;
                                    margin-top: 5px;

                                    li {
                                        list-style-type: none;
                                        margin-bottom: 5px;

                                        a {
                                            text-decoration: none;
                                            color: #393939 ;
                                            font-family: 'HelveticaNeueLTStd-Lt';

                                            span:after {
                                                bottom: -2px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media(min-width: $min-desktop) {
                justify-content: space-between;

                .upper-links {
                    display: flex;
                    align-items: center;

                    a {
                        margin-right: 20px;
                        color: $grey-on-white;
                        text-decoration: none;
                        padding-top: 3px;
                    }
                }

                .geo-settings {
                    justify-content: flex-end;
                    width: auto;

                    .geo-setting {
                        width: auto;
                        padding-top: 3px;

                        &:first-of-type {
                            border-right: none;
                        }

                        &.language-filter {

                            span {
                                svg {
                                    width: 14px;
                                    height: 14px;
                                }
                            }

                            .language-dropdown {
                                width: 370px;
                                right: 0;
                                left: auto;
                            }
                        }
                    }
                }
            }
        }

        .lower-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            padding-bottom: 17px;

            @media(min-width: $min-desktop) {
                padding: 20px 0;
            }


            .logo {
                flex-grow: 2;
                text-align: center;

                .main-logo {
                    display: none;
                }
            }

            .header-icons {

                button {
                    background-color: transparent;
                    padding: 0px;
                }

                .header-search-btn {
                    cursor: pointer;
                    display: flex;
                        padding-bottom: 3px;
                        position: relative;

                    &:after {
                        content: "";
                        height: 1px;
                        width: 0;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        -webkit-transition: all .2s ease-in-out;
                        transition: all .2s ease-in-out;
                        background: #000;
                    }

                    &:hover {
                        &:after {
                            width: 100%;
                        }
                    }
                }

                .header-search-btn svg {
                    width: 23px;
                    height: 23px;
                }

                .shopping-basket {
                    display: flex;
                    position: relative;
                    margin-right: 15px;

                    &:after {
                        content: '';
                        height: 1px;
                        width: 0px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transition: all .2s ease-in-out;
                        background: black;
                    }


                    &.basket-active, &:hover {
                        /*border-bottom: 1px solid $black;*/
                        &:after {
                            width: 100%;
                        }
                    }

                    svg {
                        width: 24px;
                        height: 28px;
                        margin-top: 0;
                    }

                    .items {
                        @include font-size(12,12);
                        border-radius: 50%;
                        background-color: $black;
                        color: white;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        position: absolute;
                        top: 0;
                        left: -5px;
                        padding-top: 5px;
                    }
                }
            }

            &.search-active {
                justify-content: initial;

                .logo, .menu-icon-wrapper, .shopping-basket {
                    display: none;
                }
            }

            .header-search {
                flex-grow: 2;
                display: none;
                align-items: center;
                background-color: transparent;

                input {
                    border: none;
                    @include font-size(16);
                    padding: 5px 20px 0;
                    margin: 0;
                    color: black;

                    ::placeholder {
                        color: $black-2;
                    }

                    @media(min-width: 768px ){
                         padding: 5px 20px 0 0;
                    }

                    @media(min-width: $min-desktop) { 
                        @include font-size(20);  


                        ::placeholder {
                            color: $form-border;
                        }
                    }
                }

                .close-search-mob svg {
                    cursor: pointer;
                    width: 18px;
                    height: 18px;
                }

                .search-btns-desktop {
                    display: none;
                }
            }

            .header-search-active {
                display: flex;

                .close-search-mob {
                    display: flex;
                }
            }

            .menu-icon-wrapper {
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                z-index: 2;

                .menu-icon {
                    position: relative;

                    &::before, &::after {
                        position: absolute;
                        left: 0;
                        content: '';
                        display: block;
                        transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
                    }

                    &::before {
                        top: -8px;
                    }

                    &::after {
                        top: 8px;
                    }
                }

                .menu-icon, .menu-icon::before, .menu-icon::after {
                    width: 25px;
                    height: 2px;
                    background-color: $black-2;
                }

                .menu-icon-active {
                    background-color: transparent;

                    &::before, &::after {
                        top: 0;
                        transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
                        background-color: #000;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .header-search {
                margin: 0;
            }

            .header-icons {
                display: flex;
                align-items: center;
            }

            @media(min-width: $min-desktop) {

                .close-search-mob {
                    display: none;
                }

                &.search-active {
                    padding: 19px 0;

                    .logo, .menu-icon-wrapper, .primary-navigation, .header-icons {
                        display: none;
                    }

                    .close-search-mob {
                        display: none;
                    }


                    .search-btns-desktop {
                        display: flex;

                        button {
                            &:first-of-type {
                                margin-right: 10px;
                            }

                            display: flex;
                            align-items: center;

                            svg {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            @media(min-width: $huge) {
                padding: 0px;

                .logo {
                    .main-logo {
                        display: initial;
                        height: 38px;
                        width: auto;
                        object-fit: contain;
                    }

                    .mobile-active {
                        display: none;
                    }
                }

                .header-icons {
                    display: flex;
                    align-items: center;

                    .header-search-btn {
                        margin-left: 15px;

                        svg {
                            width: 26px;
                            height: 26px;
                        }
                    }

                }

                .menu-icon-wrapper {
                    display: none;
                }
            }
        }

        &.nav-active {

            .lower-nav {
                justify-content: space-between;

                .header-icons {

                    .header-search-btn {
                        display: none;
                    }

                    .shopping-basket {
                        display: block;
                    }
                }
            }
        }

        @media(min-width: $min-desktop) {
            padding: 0 40px;
        }
    }
}

body {
 
 &:before {
    content: '';
    background-color: rgba(0,0,0,.40);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: none;
    opacity: 0;
    transition: .2s ease;
  }

} 

body.overlay-open {
    &:before {
      opacity: 1;
      display: block;
    }
 
}