.main {

  position: relative;
  margin-top: 106px;


	@media(min-width: $huge) {
		margin-top: 138px;
	}

	.container {

		&.text-img-split {
			display: flex;
			flex-direction: column-reverse;

			@media(min-width: $max-tablet) {
				flex-direction: row;
				align-items: center;
				justify-content: center;

				&.left-True {

					.tis-content {
						order: 2;
						margin-left: 2rem;
					}

					.tis-img-wrapper {
						order: 1;
					}
				}

				&.left-False {
					.tis-content {
						margin-right: 2rem;
					}
				}
			}

			.tis-content {

				h2 {
					@include font-size(22,32);
					margin: 1.5rem 0;
				}

				@include font-size(16,26);

				@media(min-width: $max-tablet) {
					flex: 1;
				}
			}

			.tis-img-wrapper {
				position: relative;

				@media(min-width: $max-tablet) {
					flex: 1;
				}

				.tis-image {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					width: 100%;
					height: 260px;

					@media(min-width: $max-tablet) {
						height: 403px;
					}
				}

				.img-caption {
					@include font-size(12,22);
					color: $black-3;
					font-style: italic;
					margin: 0.5rem 0;

					&.light-True {

						@media (min-width: $small-desktop) {
							color: $light-caption-text;
						}
					}

					@media(min-width: $small-desktop) {
						position: absolute;
						left: -2rem;
						bottom: 0;

						&:before {
							background-color: $dark-caption-line;
							content: "";
							display: inline-block;
							height: 1px;
							position: relative;
							vertical-align: middle;
							width: 70px;
							margin-right: 1.25rem;
						}

						&.light-True {
							&:before {
								background-color: $light-caption-line;
							}
						}
					}
				}
			}
		}
	}
}

.error-page {

	.ctas {
		display: flex;

		.button {

		    align-self: flex-start;		

			&.back {

				margin-top: 40px;

				svg {
					margin: 0 10px 0 0;
				}

			}
		}
	}

	
}

.notification-bar {
	display: flex;
	align-items: center;
	padding: 15px 25px;

	svg {
		width: 32px;
		height: 32px;

		@media (min-width: $max-tablet) {
			width: 16px;
			height: 16px;
		}
	}

	span {
		display: block;
		@include font-size(14,20);
		font-family: HelveticaNeueLTStd-Md;
		margin: 4px 0 0 15px;

		@media (min-width: $max-tablet) {
			@include font-size(16,30);
		}
	}

	&.alert-green {
		background-color: $notificationbg-green;

		span {
			color: $notificationtxt-green;
		}
	}

	&.alert-grey {
		background-color: $notificationbg-grey;

		span {
			color: $notificationtxt-grey;
		}
	}

	&.alert-red {
		background-color: $notificationbg-red;

		span {
			color: $red;
		}
	}
}