﻿.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; 
  padding: 0 30px;

  &.pad-30 {
    padding: 30px;
  }

  &.large {
     max-width: 1366px;
  }

  &.article {

    .container {
      padding: 0;
    }
  }

  &.bottom {
    padding-bottom: 0;
  }

  &.full {
    max-width: 100%;
    padding: 0;
  }

  &.mid {
    max-width: 1005px;
  }

  &.narrow {
    max-width: 810px;
  }

  &.filter-container {
    padding: 0 20px;

    @media(min-width: 600px) {
        padding: 0 40px;
    }

    @media(min-width: 1220px) {
        padding: 0 20px;
    }
  }
}

section {
   margin: 40px auto; 

  @media(min-width: $small-desktop) {
    margin: 60px auto;
  }
}
