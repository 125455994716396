.primary-navigation {
  display: none;
  border-top: 1px solid $light-grey;
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  background-color: $white;
  left: 0;
  top: 115px;

  .mobile-partner-links {

    display: flex;
    flex-direction: column;

    a {
      @include font-size(15);
      color: $grey-on-white;
      margin: 20px 20px 0 20px;
      font-family: 'HelveticaNeueLTStd-Lt';
    }

    @media(min-width: $max-desktop) {
      display: none;
    }

  }

  ul {
    width: 100%;
    margin-bottom: 0;

    li {
      display: flex; 
      align-items: center;
      justify-content: space-between;
      height: 25px;

       @media(min-width: $max-desktop) {
      height: 45px;
    }

      @include font-size(14, 14);
      margin: 20px 20px 0 20px;
      text-transform: uppercase;

      &.logo-wrapper {
        display: none;
      }

      a {
        color: $black-2;
        text-decoration: none;
        letter-spacing: 2px;
        font-family: 'HelveticaNeueLTStd-Lt';
        align-self: center;

        @media(min-width: $small-desktop) {
          padding-top: 7px;
        }
      }

      .nav-child-link {
        background-color: transparent;
        padding: 0;

        svg {
          width: 36px;
          height: 40px;
        }
      }

      .sub-navigation {
        position: absolute;
        left: 100%;
        top: 0;        
        width: 100%;
        background: $grey-bg;
        padding: 20px 20px 0 20px;
        transition: .2s linear;
        z-index: 99;

        @media(max-width: 1199px) {
          bottom: 0;
          overflow: auto;
        }
        

        .sub-nav-content-wrapper {
          padding: 0px;
          .sub-nav-image {
            display: none;
          }
        }


        button {
          background-color: transparent;
          font-family: 'HelveticaNeueLTStd-Md';
          display: flex;
          padding: 0;
          margin-top: 20px;
          align-items: center;

          svg {
            width: 25px;
            height: 20px;
            margin-right: 10px;
          }
        }

        ul {
          li {
            margin-left: 0;
            height: 20px;

             @media(min-width: $max-desktop) {
                height: 40px;
              }
          }
        }

        &.is-active {
          left: 0;
        }
      }
    }
  }

  @media(min-width: $huge) {
    display: flex;
    border: none;
    justify-content: center;
    min-height: 0;
    max-height: inherit;
    position: inherit;
    left: auto;
    top: auto;
    overflow: inherit;

    ul {
      display: flex;
      margin-bottom: 0;
      justify-content: center;

      li {
        @include font-size(14);
        margin: 0;
        flex-grow: 1;
        justify-content: flex-start;
        margin: 0;
        padding: 30px 10px;
        height: auto;

        a {
          align-self: center;
        }

        &:first-of-type {
          padding-left: 0px;
        }

        &.logo-wrapper {
          display: inherit;
          flex-grow: 2;
        }

        .nav-child-link, .sub-navigation {
          display: none;
        }

        .sub-navigation {
          left: 0;
          height: auto;
          z-index: 999;
          top: 138px;
          padding: 0px;
          background: none;

          .back-link {
            display: none;
          }

          .sub-nav-content-wrapper {
            background: $grey-bg;
            width: 100%;
            
            .sub-nav-content {
              width: 100vw;
              padding: 0px;
              display: flex;
              align-items: center;
              padding: 0 40px 0 20px;
              justify-content: space-between;
  
              ul {
                flex-direction: column;
                max-height: 135px;
                flex-wrap: wrap;
                width: 35%;
                margin: 30px 0;
  
                li {
                  padding: 10px 20px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  border-right: 1px solid $border;
                  a {
                      margin-right: 50px;
                  }
                }
              }
  
              .sub-nav-image {
                display: inherit;
                img {
                  max-width: 350px;
                }
              }
            }
          }

        }

        &:hover {

          .sub-navigation {
            display: inherit;
            max-height: 900px;

            &:hover {
              display: inherit;
              max-height: 900px;
            }
          }
        }
      }
    }
  }

  @media(min-width:$mega) {
    ul{
      li {
        padding: 30px 20px;
      }
    }
  }
}

@media(min-width: $huge) {
  .site-header {
    &.compact {
      .primary-navigation {
        ul {
          li {     
            .sub-navigation {
              top: 98px;
            }
          }
        }
      }
    }
  }
}
