.search-area {

  &.cancel-transitions {
    @include cancel-transitions();
  }
}

.search-form {
  position: relative;
  @include placeholder-styles() {
    color: rgba(0, 0, 0, .5);
  }

  // Included in mark-up for accessibility
  label {
    visibility: hidden;
    height: 0;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  input {
    float: right;
    background-color: transparent;
    background-image: url(../images/icons/Search_Icon.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 21px;    
    height: 21px;
    min-height: 0;
    line-height: 21px !important;
    padding: 0 25px 0 0;
    border: none;
    border-left: 1px solid $black;
    width: 31px;
    cursor: pointer;
    transition: all .3s ease;
    color: rgba(255, 255, 255, 0);
    position: relative;
    z-index: 2;

    &:focus {
      width: 150px;
      border-left: none;
      color: $black;
      z-index: 0;
    }

   /* @include media($small) {
      font-size: 16px;
    }*/
  }

  input[type=submit] {
    position: absolute;
    width: 31px;
    height: 21px;
    opacity: 0;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    border: none;
    z-index: 1;
  }

  &:after {
    @include cf();
  }
}

.large-search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transform-origin: 100% 0;
  transform: scale(0, 0);
  background-color: rgba(0, 0, 0, .9);
  transition: all .5s ease .5s;

  // Included in mark-up for accessibility
  label {
    visibility: hidden;
    height: 0;
  }

  // IE9 fix
  html.no-flexbox & {
    display: block;
    padding-top: 40vh;

    fieldset {
      display: block;
      margin: 0 auto;
    }
  }

  fieldset {
    width: 300px;
    border: none;
    border-bottom: 3px solid $white;
    padding: 0 0 5px;
    opacity: 0;
    transition: opacity .3s ease;
    @include placeholder-styles() {
      color: rgba(255, 255, 255, .5);
    }

    @include media($small) {
      width: 75%;
    }

    input {
      background-color: transparent;
      border: none;
      color: $white;
      float: left;
      height: auto;
      padding: 0;
      $emToCalc: em(21);
      width: calc(100% - #{$emToCalc});

      &[type=submit] {
        //background-image: url(../images/icons/Search_Icon.png);
        background-color: red;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 21px;
        width: 21px;
        height: 21px;
        text-indent: -2000px;
      }
    }
  }

  &.active {
    transform: scale(1,1);
    transition: all .5s ease;

    fieldset {
      opacity: 1;
      transition: opacity .3s ease .75s;
    }
  }
}
