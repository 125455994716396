﻿footer {
  background-color: $black;
  color: $grey-on-black;

  a, a span {
    color: $grey-on-black;
    text-decoration: none;
  }

  ul {
    li {
      list-style-type: none;
    }
  }

  .footer-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 40px;

    .col {
      margin: 0 auto;
      text-align: center;
      padding-top: 20px;
      @include font-size(16);
          min-width: 210px;

      ul {
        li {
          padding: 5px;

          &.partner-links-txt{
              color: $white;
          }

          a {
            font-family: 'HelveticaNeueLTStd-Lt';
            display: inline-block; 

            span {
              width: 100%;
              justify-content: center;

              @media (min-width: 768px){
                justify-content: flex-start;
              }

              &:after {
                background-color: $grey-on-black;
                bottom: -1px;
                height: 0.5px;
              }
            }
          }
        }
      }


      .footer-social-links {
        padding-top: 40px;

        ul {
          display: flex;
          justify-content: space-around;

          li {
            a {

              color: #666666;

              svg {
                width: 17px;
                height: 17px;
                transition: .2s linear;
              }

              &:hover {
                color: white;

                svg  {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-disclaimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 30px;
    margin-bottom: 0px;
    padding: 20px 0;
    border-top: 1px solid $dark-grey-button-hover;
    font-size: 14px;

    .col {
      ul {
        display: flex;
        margin-bottom: 0px;

        li {
          padding: 0px 10px;
          a {
            font-family: 'HelveticaNeueLTStd-Lt';

            span {
              &:after {
                background-color: $grey-on-black;
                bottom: -1px;
                height: 0.5px;
              }
            }
          }
        }
      }
    }

    .scroll-top {
      margin-top: 0;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $grey-on-black;
      padding-right: 6px;
      font-family: 'HelveticaNeueLTStd-Lt';
      align-self: center;

      svg {
        width: 15px;
        height: 15px;
        margin: 0 5px;
        margin-bottom: 5px;
      }

      span {
        &:after {
          background-color: $grey-on-black;
          bottom: -1px;
          height: 0.5px;
        }
      }
    }
  }

  @media(min-width: $min-desktop) {

    .footer-container {
      max-width: $max-desktop;
      margin: 0 auto;

      .col {
        margin: 0;
      }

      .footer-inner {
        flex-direction: row;
        flex-wrap: wrap;

        .col {
          max-width: 50%;
          width: 50%;

          .footer-social-links {
            ul {
              justify-content: center;
              padding: 5px 12px;
            }
          }
        }
      }

      .footer-disclaimer {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        margin-bottom: 0px;
      }
    }
  }

  @media(min-width: $small-desktop) {
    .footer-container {
      padding: 0 40px;

      .footer-inner {
        flex-direction: row;
        flex-wrap: wrap;

        .col {
          min-width: 200px;
          width: auto;

          ul {
            text-align: left;
          }
        }

      }
      
      .footer-disclaimer {
        .col {
          ul {
            text-align: left;
            li {
              &:first-of-type {
                padding-left: 0;
              }
            }
          }
        }
        
        .scroll-top {
          padding-right: 0;
        }
      }
    }
  }
}
