.sub-category-header {
  display: flex;
  flex-direction: column;
  align-items: center; 

  button { 
    width: 100%;

    .icon-close-black, .filter-open {
      display: none;
    }

    &.filter-active {
      .icon-close-black, .filter-open {
        display: inherit;
      }

      .icon-filter, .filter-closed {
        display: none;
      }
    }
  }
}

.product-category-filter {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;

    &.filter-active {
        max-height: 1300px;
        transition: max-height 0.5s ease-in;

        &.filter-overflow {
            overflow: unset;
        }
    }

    .filter-form {
        margin: 20px 0 0;
        padding: 1.5rem 1rem;
        display: flex;
        flex-wrap: wrap;
        background-color: $grey-bg;

        @media(min-width: $min-tablet) {
            padding: 40px;
        }

        fieldset {
            border: none;
            width: 100%;
        }

        .buttons {
            margin-top: 0.5rem;

            .button-black {
                margin-right: 10px;
            }
        }
    }
}


@media(min-width: $min-tablet) {
  .sub-category-header {
    justify-content: space-between;
    flex-direction: row;

    h4 {
      margin: 0;
    }

    button {
      width: initial;
    }
  }
}

@media(min-width: $min-desktop) {
  .product-category-filter {

    .filter-form {
    
      fieldset {
        width: 49%;
      }
    }
  }
}

@media(min-width: $small-desktop) {
  .product-category-filter {

    .filter-form {
    
      fieldset {
        width: 32.7%;
      }
    }
  }
}

.product-listings {
    padding: 0;

    &.product-listings-detail {
        .sub-category-container {
            .related-product-header {
                text-align: center;
            }

            .sub-category-wrapper {
                .sub-category-product {
                    .product-listing {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .sub-category-container {
        position: relative;
        margin: 40px 0px;

        &.grey-container {
            &:before {
                content: '';
                background-color: $grey-bg;
                position: absolute;
                top: 130px;
                bottom: 0px;
                left: 0;
                width: 100%;
            }
        }


        .sub-category-wrapper {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            padding: 0 10px;

            .sub-category-product {
                display: flex;
                flex: 0 0 100%;
                max-width: 100%;

                .product-listing {
                    padding: 10px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    @media(min-width: $min-tablet) {
                        margin-top: 0;
                    }

                    img {
                        width: 100%;
                    }

                    .sub-category-content {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        justify-content: space-between;
                        padding-top: 20px;

                        &-inner {

                            display: flex;
                            flex-direction: column;
                            flex-grow: 1;
                            justify-content: space-between;

                            h3 {
                                
                                margin-bottom: 10px;
    
                                a.underline {
                                    font-family: 'HelveticaNeueLTStd-Roman';
                                }
                            }
    
                            .cat {
                                color: $form-border;
                                @include font-size(14);
                                margin-bottom: 14px;
                            }
    
                            .product-price {
                                margin: 0;
                            }
    
                            .compare-product, .remove-comparison {
                              padding-left: 0;
    
                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }


                        .view-product {
                            margin: 1rem 0;
                        }
                    }
                }
            }
        }

        .pagination-container {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 0;

            @media(min-width: $max-tablet) {
                padding: 60px 0;
            }

            .selectric {
                font-family: 'HelveticaNeueLTStd-Md';
            }

            .listing-select {
                margin-bottom: 30px;
            }

            .pagination-wrapper {
                margin: 0;
            }

            #listing-size {
                -webkit-appearance: none;
                background: url(/common/images/icons/sprite-icon-dropdown-arrow.svg) no-repeat scroll calc(62% + 4px) center/18px auto;
                border: 1px solid #000;
                height: 58px;
                width: 85px;
                position: relative;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.5rem;
                padding: 0 1.45rem;
            }
        }

        @media(min-width: 600px) {
            margin: 40px 20px;

            .pagination-container {
                justify-content: space-between;
                flex-direction: row;
                    padding: 20px 10px 60px;

                .listing-select {
                    margin-bottom: 0;
                }

                .pagination-wrapper {
                    margin: 0;
                }
            }

            .sub-category-wrapper {

                .sub-category-product {
                    flex: 0 0 50%;
                    max-width: 50%;

                    .product-listing {
                        margin-bottom: 20px;
                        margin-top: 0;
                    }
                }
            }
        }

        @media(min-width: $small-desktop) {
            .sub-category-wrapper {

                .sub-category-product {
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                &.feature-product-pod {
                    .sub-category-product {
                        flex: 0 0 33%;
                        max-width: 33%;
                    }
                }

                &.item-count-1 {
                    justify-content: center;

                    .sub-category-product {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                &.item-count-2 {
                    .sub-category-product {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }
    }
}


.prod-img-holder {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 240px;
  background-position: center;
}

.feature-product-pod {
  .prod-img-holder {
    height: 330px;
  }
}
