.downloads-intro {
	display: flex;
	flex-direction: column;


	.button {
		align-self: flex-start;
		margin-top: 20px;

		&.product-download {

			svg {
				margin: 0 10px 0 0;
			}
		}
	}
}

.download-group {
  position: relative;
  background-color: $grey-bg;
  padding: 40px 30px;
  margin-bottom: 30px;

  @media (min-width: $max-tablet) {
     padding: 50px 40px;
  }

 @media (min-width: $small-desktop) {
    padding: 50px 60px;
  }

  h3 {
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
  }

	&:after {
	    content: '';
	    background-color: $grey-bg;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 50%;
	    width: 100%;
      z-index: 0;
	  }


	.download-links {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;

    a {
      width: 100%; 
      margin-bottom: 30px;

      @media (min-width: 768px) {
        width: 50%;
      }

      @media (min-width: 980px) {
        width: 30%;
      }
    }
	}
}