.colour-intro {

    .button.view-finishes-cta {

        svg {
            width: 35px;
            height: 35px;

        }
    }
}

.color-group-header {
    margin-bottom: 40px;

    img {
        max-width: 595px;
        width: 90%;
    }
}

.pallet-container {
    position: relative;

    h4 {
        font-family: 'HelveticaNeueLTStd-Md';
        margin-left: 10px;
    }

    h4.collaboration {
        margin-bottom: 40px;

        img {
            max-height: 46px;
            width: auto;
            margin-bottom: 2px;
        }
    }
}

.pallet-grid {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    position: relative;

    &:before {
        content: '';
        background-color: #f5f5f5;
        position: absolute;
        top: 60px;
        bottom: 0;
        left: -20px;
        right: -20px;
        z-index: 0;

        @media (min-width: $small-desktop) {
            left: -60px;
            right: -60px;
        }
    }

    .pallet {
        width: 100%;
        margin: 10px 0;
        z-index: 2;

        .img {
            background-size: cover;
            background-repeat: no-repeat;
            padding-bottom: 45%;
            margin-bottom: 30px;
            position: relative;
            height: 320px;

            .colour-details {
                position: absolute;
                bottom: 6px;
                left: 15px;
            }

            p {
                margin: 0;
                text-transform: uppercase;
                @include font-size(13,18);
            }
        }

        h3 {
            span {
                font-family: 'HelveticaNeueLTStd-Roman';
            }
        }

        p {
            padding-right: 20px;
        }

        @media (min-width: $max-tablet) {
            width: calc(50% - 20px);
            margin: 10px;
        }

        @media (min-width: $small-desktop) {
            width: calc(33% - 20px);
            padding: 0 0 20px 0;
        }
    }
}
