#CybotCookiebotDialog {
    font-family: HelveticaNeueLTStd-Lt !important;
    font-size: 14px !important;
    #CybotCookiebotDialogBodyContentTitle {
        font-family: HelveticaNeueLTStd-Md !important;
        font-size: 18px;
    }
}

.CybotCookiebotDialogContentWrapper {
    @media(min-width: $max-desktop) {
        width: 1200px;
        max-width: 1200px !important;
    }

    #CybotCookiebotDialogHeader {
        display: none !important;
    }
}
#CybotCookiebotDialogBodyButtonAccept {
    &.CybotCookiebotDialogBodyButton {
        background-color: #000000 !important;
        border-color: #000000 !important;
    }
}

#CybotCookiebotDialogBodyButtonDecline {
    &.CybotCookiebotDialogBodyButton {
        border: 1px solid #000000 !important;
    }
}

#CybotCookiebotDialogBodyEdgeMoreDetails {
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
        color: $text !important;
        font-family: HelveticaNeueLTStd-Md;
    }
}

.CookieDeclarationTableHeader {
    color: $white;
}