.pagination-wrapper {

  margin-top: 50px;
  margin-bottom: 90px;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    .pag-numbers {
      display: flex;
      margin: 0 15px;
      border: 1px solid $black;
    }

    .pag-elipsis, .pag-button {
      min-width: 56px;
      min-height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-family: 'HelveticaNeueLTStd-Md';
      padding-top: 3px;
    }

    .pag-button {

     

      &:hover, &.pag-number.active {
        background-color: $border;
      }

      &.pag-arrow {
        min-width: 58px;
        min-height: 58px;
        border: 1px solid $black;

        &.pag-prev {
          svg {
            fill: $black;
          }
        }

        svg{
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  @media(min-width: $min-desktop) {
    .pagination {
      .pag-button {
        &:nth-child(2n) {
          display: inherit;
        }
      }
    }
  }
}