.breadcrumbs {
  width: 100%;
  background-color: $grey-bg;
  padding: 10px 0;
  display: flex;
  align-items: center;
  position: relative;
  
  .container {
    padding: 0 20px;

    ul {
      list-style-type: none;
      display: flex;
      margin: 0;
      flex-wrap: wrap;
  
      li {
        @include font-size(13,13);

        @media(min-width: $max-tablet) {
          @include font-size(14,14);
        }

        display: flex;
        padding-top: 4px;

        a.underline {
          font-family: 'HelveticaNeueLTStd-Lt';

          .bold {
            font-family: 'HelveticaNeueLTStd-Md';
          }
        }



        .breadcrumb-slash {
          margin: 0 10px;
        }
      }
    }
  }

  @media(min-width: $min-tablet) {
    height: 60px;

    .container {
      padding: 0 40px;
    }

  }
}