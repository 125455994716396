html,
body {
  min-height: 100%;
}

body {
  transition: left .3s ease;
  position: relative;
  width: 100%;
  left: 0;
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
    overflow: hidden;
    overflow-y: auto;
  -webkit-font-smoothing: antialiased;

  &.open {
  position: fixed;
  left: 240px;
  }

  &.search-active {
  position: fixed;
  width: 100%;
  }

  &.breakpoint-indicators {

    &:after {
      content: 'Mega';
      position: fixed;
      font-size: 12px;
      background: $grey-1;
      color: $white;
      bottom: 0;
      left: 0;
      padding: 5px 10px;
      letter-spacing: 1px;
      z-index: 3000;

      @include media($huge) {
      content: 'Huge';
      }

      @include media($large) {
      content: 'Large';
      }

      @include media($medium) {
      content: 'Medium';
      }

      @include media($small) {
      content: 'Small';
      }

      @include media($tiny) {
      content: 'Tiny';
      }
    }
  }
}

* {
  box-sizing: border-box;
}

.inline {
  display: inline-block;
}

.hidden {
  display: none;
}

strong {
  font-weight: bold;
}

ul {
  padding-left: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

// Fixes issues with google maps images not showing correctly
// img[src*="gstatic.com/"], img[src*="googleapis.com/"] {
//  max-width: none;
// }

a, button {
  color: #333333;
  background-color: transparent;
}

a, button, span {

  &.underline {
    color: #333333;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    position: relative;
    text-decoration: none; 
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    font-family: 'HelveticaNeueLTStd-Md', sans-serif;
    // padding-right: 26px;

    &.back span {
      padding-top: 4px;
    }

    &.light-text {
      font-family: 'HelveticaNeueLTStd-Lt', sans-serif;
    }

    &.compare-product {
      .icon-minus, 
      .remove-comparison-text {
        display: none;
      }

      &.disabled {
        color: $grey-on-black;

        svg {
          fill: $grey-on-black;
        }
      }

    }

    &.remove-comparison {
      .icon-compare, 
      .comparison-text {
        display: none;
      }
    }

    &.align-left {
      justify-content: flex-start;
    }

    span {
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        width: 0px;
        height: 1px;
        margin: 1px 0 0;
        transition: all .2s ease-in-out;              
        background-color: $black;
        left: 0;
      }

      &.hidden {
        display: none;
      }
    }    

    svg {
      // position: absolute;
      // right: 0;
      // top: 50%;
      // transform: translateY(-50%);
      width: 16px;
      height: 16px;
      margin-left: 10px;
      margin-bottom: 5px;
      transition: all .1s linear .2s;

      &.icon-compare, &.icon-left {
        margin-left: 0px;
        margin-right: 10px;
      }
    }

    &.white-underline {
      color: $white;

      span {
        &:after { 
          background-color: $white;
        }
      } 

      svg {
        fill: $white;
      }

    }

    @media (hover) {
      &:hover,
      a:hover & {
        cursor: pointer;
  
        span {
          &:after {
            width: 100%;         
          }
        }      
  
        svg {
          margin-left: 15px;
          // right: -5px;
  
          &.icon-down {
            margin-left: 10px;
            margin-bottom: 0;
          }
  
          &.icon-compare, &.icon-left {
            margin-left: 0;
            /*margin-right: 15px;*/
          }
        }
      }
    }

    &.shopping-basket:hover svg { margin-left:10px; }
  }
}

// Hide SVG Sprite
.svg-sprite {
  display: none;
}