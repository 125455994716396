/* We're using an inner-outer file structure here so that the umbraco text
  editor in the CMS can use a different scss file which imports the inner
  file but not the outer file. This file will be used by the site as well
  as the CMS, richtext.scss will only be used by the site, not the cms
*/
.left-float {
  margin: 0 10px 10px 0;
}

.right-float {
  margin: 0 0 10px 10px;
}

p {
  a {
    text-decoration: none;
    transition: color .2s ease-in;

    &:hover {
      text-decoration: underline;
    }
  }
}

img {
  margin-bottom: 1rem;
}

table {
  width: 100%;
  display: block;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  border-collapse: collapse;
  margin: 30px 0;

  th,td {
    padding: 10px 15px;
  }

  thead th {
    text-align: left;
    font-weight: bold;
  }

  tbody {
    tr {
      &:nth-child(odd) {
      }

      &:nth-child(even) {

      }
    }
  }
}

ul {
  list-style: disc outside;
  padding-left: 1.5em;
}

ol {
  list-style: decimal outside;
  padding-left: 1.5em;
}

li {
  padding-bottom: 8px;
  line-height: 20px;
}

/* TODO: Add blockquote styling */
blockquote {
  position: relative;

  &:before,
  &:after {
    content: '\0201C';
    position: absolute;
    font-style: normal;
    top: 0;
    left: 0;
  }

  &:after {
    content: '\0201D';
  }

  &:after {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
}