﻿.pac-container.pac-logo {
    // top: 175px !important;

    &:after{
        background-image: none !important;
    }

    // @media(min-width: $small-desktop) {
    //     top: 34.2rem !important;
    // }

    // @media(min-width: $max-lg-desktop) {
    //     top: 35rem !important;
    // }
}
.open-category-filter {
    background: #f5f5f5;
    max-height: 1300px;
    margin: 0 -30px 3rem;


    @media(min-width: $max-tablet) {
        margin: 0 0 3rem;

    }

    h3 {
        padding-left: 15px;
        width: 100%;
    }


    .filter-form {
        margin: 0px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;

         @media(min-width: $max-tablet) {
             padding: 40px;

        }

        .buttons {
            padding: 3.35em 0.625em 0.75em;
        }

        button:disabled,
        button[disabled] {
            border: 1px solid $dark-grey-button-hover;
            background-color: $grey-button-hover;
            color: $dark-grey-button-hover;

            &:hover {
               
               &:before {
                display: none;
               }
            }
        }

        fieldset {
            border: none;
            width: 100%;

            .select-wrapper {
                .validation-msg {
                    display: none;
                }

                label {
                    letter-spacing: 2.32px;
                }

                .location-items {
                    /*margin-bottom: 2rem;*/

                    input {
                        margin-bottom: 17px;
                    }

                    .location-marker {
                        svg {
                            width: 16px;
                            height: 16px;
                        }

                        span {
                            font-family: 'HelveticaNeueLTStd-Md';
                            @include font-size(16,21);
                            color: $black;
                        }

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }
                    }
                }
            }

            input {
                padding: 12px 12px 12px 0;
            }
        }
    }
}
@media only screen and (min-width: $min-desktop) {
    .open-category-filter {
        .filter-form {
            .buttons {
                padding: 0.35em 0.625em 0.75em;
            }

            fieldset {
                width: 45%;
            }
        }
    }
 }

@media only screen and (min-width: 1204px) {

    .open-category-filter {
        .filter-form {

            fieldset {
                .select-wrapper {

                    .location-items {
                        display: flex;
                        align-items: baseline;

                        input[type=text] {
                            width: 65%;
                        }

                        .location-marker {
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }

}
.container.showroom {
    margin-top: 4rem;

    @media(min-width: $max-tablet) {
        margin: 4rem auto;
    }



    h2 {
        text-align: center;
        @include font-size(26,36);
    }



    .secondary-title {
        border-bottom: 1px solid $table-grey;
        padding: 1.5rem 0;
    }

    .nearest-results {
        border-bottom: 1px solid $table-grey;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;

        &.premier-showroom {
            border: 4px solid black;
            padding: 15px 15px 0;
            margin-top: 37px;

            @media(min-width: $max-tablet) {
                padding: 39px 0 39px 28px;
            }
        }

        .location-title {
            display: flex;
            align-items: center;
            position: relative;
            padding-top: 5px;

            &:hover {
                cursor: pointer;
            }

            svg {
                width: 22px;
                height: 39px;

                .pin-value {
                    @include font-size(10);
                    color: $black;
                    font-family: 'HelveticaNeueLTStd-Md';
                }
            }

            .location-title-icon {
                width: 15%;

                @media(min-width: $max-tablet) {
                    width: 6%;
                }

                @media(min-width: $small-desktop) {
                    width: 4%;
                }
            }

            .location-title-text {
                width: 80%;

                p {
                    margin: 0;
                    @include font-size(20,30);
                    font-family: 'HelveticaNeueLTStd-Md';
                }

                span {
                    @include font-size(13);
                    letter-spacing: 2.32px;
                    color: $brown-grey;
                    text-transform: uppercase;
                }
            }

            .btn-toggle {
                text-align: right;
                width: 10%;
                align-self: self-end;
                margin-top: 4px;

                .accordion-plus, .accordion-minus {
                    display: none;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .location-details {
            margin-top: 2rem;
            display: none;

            .location-details-wrapper {


                @media(min-width: $max-tablet) {
                    display: flex;
                    flex-wrap: wrap;

                    & > div {
                        flex-grow: 0;
                        flex-shrink: 0;
                        flex-basis: 33%;
                    }
                }

                .location-address {

                    .location-address-title {
                        @include font-size(13);
                        color: $text;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }

                    .location-address-content {
                        color: $grey-on-white;
                    }

                    a.underline {
                        justify-content: left;
                        margin-bottom: 0.5rem;
                    }
                }

                .location-phone {
                    margin-bottom: 1rem;

                    @media(min-width: $max-tablet) {
                        padding: 0 2rem;
                    }

                    .location-phone-title {
                        @include font-size(13);
                        color: $text;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }

                    a {
                        text-decoration: none;
                    }

                    svg {
                        height: 16px;
                        width: 11px;
                    }
                }

                .location-website {
                    display: flex;

                    a {
                        align-self: flex-start;

                        @media(min-width: $max-tablet) {
                            max-width: 85%;
                            float: right;
                        }

                        svg {
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }

        .location-details.first-item {
            display: block;
        }

        .location-products {
            margin: 2rem 0;


            p {
                @include font-size(13);
                color: $text;
                letter-spacing: 2.32px;
                text-transform: uppercase;
            }

            a.underline {
                justify-content: left;
                margin-bottom: 0.5rem;
            }

            .product-links {
                display: flex;
                flex-wrap: wrap;

                & > a {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: 50%;
                }

                @media(min-width: $max-tablet) {

                    & > a {
                        flex-basis: 33%;
                    }
                }
            }
        }
    }

    .premier-content {

        .premier-content-title {
            display: flex;
            align-items: baseline;

            svg {
                width: 15px;
                height: 13px;
                margin-right: 10px;
                fill: #ED4136;
            }

            h5 {
                font-family: 'HelveticaNeueLTStd-Md', sans-serif;
            }
        }
    }
}
.container.showroom-map {
    /*display: none;*/
    h2 {
        text-align: center;
       
    }

    #map {
        height: 376px !important;
        margin: 40px 0 !important;

        @media(min-width: $max-tablet) {
            height: 650px !important;
        }
    }
}

.no-results-wrapper {

  display: none;

  .no-results {
    max-width: $max-tablet;
    margin-bottom: 35px;
    h2 {
      text-align: center;
    }
  }
}

