.popup {
    margin-top: 40px;

    .popup-container {
        background-color: $white;
        padding: 30px;

        .popup-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            text-align: left;

            h3 {
                margin: 0;
                width: 100%;
                text-align: center;

                @media(min-width: $max-tablet) {
                   text-align: left;
                }
            }
        }

        .subtitle-txt {
            @include font-size(20,30);
            font-family: 'HelveticaNeueLTStd-Bd';
        }
    }



    &.download-popup {

        .popup-container {
            max-width: $large;

            .file-download-form,
            #success-message {
                padding: 40px;
            }

            #success-message {
                display: none;
                background-color: $grey-bg;
            }

            .file-download-form {
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                padding: 40px;
                background: $grey-bg;

                fieldset {
                    border: none;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                .form-submit {
                    width: 100%;
                    flex: 0 0 100%;
                    margin: 0 2px;
                    padding: 0.35em 0.625em 0.75em;

                    .form-cta-download {
                        margin-top: 40px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    &.out-of-stock-popup {
        display: flex;
        justify-content: center;

        .out-of-stock {
            max-width: 520px;

            .out-of-stock-text {
                background-color: $grey-bg;
                padding: 40px;

                p {
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

    &.products-popup {

        .popup-container {
            max-width: 520px;
        }

        .products-popup-container {
            background-color: $grey-bg;
            padding: 40px 20px 15px 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .product-popup-wrapper {
                width: 100%;

                @media (min-width: $max-tablet) {
                    width: 50%;
                }

                @media (min-width: $small-desktop) {
                    width: 33%;
                }
            }

            .product-popup {
                text-align: center;
                padding: 0 10px 25px 10px;
                align-items: center;
                display: flex;
                flex-direction: column;

                .product-popup-image {
                    margin-bottom: 15px;
                    max-width: 100%;
                    border: 1px solid #DEDEDE;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 140px;
                    width: 100%;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.customise-buy-popup {
        padding: 50px 0;

        .popup-container {
            padding: 10px;
        }

        .popup-header {
            justify-content: flex-end;
            flex-direction: column-reverse;
            align-items: flex-end;
            padding: 0 10px;
        }

        .customise-buy-form {
            background-color: $white;
            margin-left: 0;

            fieldset {
                background-color: $grey-bg;
                border: none;
                margin-bottom: 10px;
                padding: 30px 30px 10px 30px;

                label {
                    margin-bottom: 0;
                }

                .select-wrapper {
                    margin-bottom: 30px;
                }

                .customise-buy-buttons {
                    display: flex;
                    margin-bottom: 30px;
                    flex-wrap: wrap;
                    flex-direction: column;

                    button {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    &.colour-tool-popup {

        margin-top: 0px;

        .colour-tool {
            margin: 15px 0px;
            padding: 0;

            .colour-tool-header {
                justify-content: flex-end;
                flex-wrap: wrap-reverse;
                padding: 0 10px;
                padding-top: 1rem;

                button {
                    margin-bottom: 1rem;

                    svg {
                        margin-bottom: 5px;
                    }
                }

                .icon-wallpaper-x-victoria-albert {
                    height: 23px;
                    margin-bottom: 7px;
                    width: 100%;
                }
            }

            .popup-image {
                display: inline-block;
                position: relative;

                #popup-image-container {

                    svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 9;
                        mix-blend-mode: multiply;

                        #path {
                            fill: $white;
                            fill-rule: evenodd;
                        }
                    }
                }

                .colour-tool-foreground-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                }

                .colour-tool-background-image {
                    display: block; /* removes random extra space on chrome */
                    max-width: 100%;
                }

                .image-tools {
                    position: absolute;
                    bottom: 0;
                    z-index: 10;
                    padding: 10px 30px 0px 30px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-end;
                    top: 0;

                    .colour-tool-code {
                        padding: 0;

                        span {
                            margin-left: 5px;
                        }
                    }

                    .colour-tool-download {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 20px;
                        right: 30px;

                        button {
                            position: initial;

                            svg {
                                margin-bottom: 10px;
                            }

                            span {
                                padding-bottom: 5px;
                            }
                        }
                    }

                    .buy-now-dropdown {
                      position: relative;

                      .buy-now-button-dropdown {
                        position: absolute;
                        width: 100%;
                        height: 0;
                        overflow: hidden;

                        &.open {
                          height: 100%;
                          overflow: visible;
                        }

                        .hidden-dropdown {
                          text-transform: none;
                          letter-spacing: 0;
                          border: 0;
                          padding: 25px 25px 15px;
                          width: 100%;
                          justify-content: flex-start;
                          font-family: 'HelveticaNeueLTStd-Lt', sans-serif;
                        }
                      }
                    }
                }
            }

            .popup-content {
                background-color: $grey-bg;
                margin: 0 10px;

                .colour-swatches-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    flex-direction: column;
                    border-bottom: 1px solid $border;
                    padding: 15px 20px;

                    &:last-child {
                        border-bottom: none;
                    }

                    .colour-swatch-header {

                        display: flex;
                        align-items: center;

                        h5 {
                            margin: 0;
                            @include font-size(18);
                        }
                    }

                    .colour-swatches {
                        display: flex;
                        flex-wrap: wrap;

                        .colour-swatch {
                            width: 50px;
                            height: 50px;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-end;

                            &:hover {
                                .speech-bubble {
                                    display: block;
                                }
                            }

                            .speech-bubble {
                                position: absolute;
                                background: $black;
                                z-index: 10;
                                margin-top: -62px;
                                padding: 5px 20px;
                                text-align: center;
                                display: none;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 50%;
                                    width: 0;
                                    height: 0;
                                    border: 10px solid transparent;
                                    border-top-color: $black;
                                    border-bottom: 0;
                                    margin-left: -10px;
                                    margin-bottom: -10px;
                                }

                                p {
                                    color: $white;
                                    margin: 0;
                                }

                                span {
                                    @include font-size(13);
                                    color: $form-border;
                                }
                            }
                        }
                    }
                }

                @media(min-width: $min-tablet) {

                  .colour-swatches-container {
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .colour-swatch-header {
                      max-width: 110px;
                    }
                  }
                }
            }

            .colour-tool-disclaimer {

                @media(max-width: $min-tablet) {
                    padding: 0.5rem 15px 1rem 15px;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}


@media(min-width: $tiny) {

  .popup {
    &.products-popup {
      .products-popup-container {
        
  
        .product-popup {
          .product-popup-image {
            max-width: 120px;
          }
        }
      }
    }
  }
}

@media(min-width: $min-tablet) {
  .popup {

    &.download-popup {

      .popup-container {
        .file-download-form {
          fieldset {
            border: none;
            max-width: 48%;
            flex: 0 0 48%;
          }
        }
      }
    }

    &.customise-buy-popup {

      .popup-container {
        padding: 30px;
      }

      .popup-header {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: center;
        flex-direction: row;
        padding: 0;
      }

      .customise-buy-form {
        fieldset {

          padding: 40px 40px 10px 40px;

          .customise-buy-buttons {
            flex-direction: row;
  
            button {
  
              width: auto;
  
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    &.colour-tool-popup {
      .colour-tool {
        padding: 30px;
        margin: 0 auto;
    
        .colour-tool-header {

          flex-wrap: nowrap;
          justify-content: space-between;
          padding: 0;

          .icon-wallpaper-x-victoria-albert {
            height: 23px;
            margin-bottom: 7px;
            width: auto;
          }
        }

        .popup-image {
          .image-tools {
            padding: 20px 30px;
            top: auto;
            flex-direction: row;
            height: 100%;
          }
        }

        .popup-content {
          margin: 0;


          .colour-swatches-container {
            .colour-swatches {
              .colour-swatch {
                width: 60px;
                height: 35px;
  
              }
            }
          }
        }
      }
    }
  }
}

.colour-popup {


  .swatch {
    background: white;
    width: 96%;
    margin: 0 auto;
    max-width: 1300px;   
    max-height: 700px;
    display: block;
    display: block;
    padding: 20px 20px 40%;
    position: relative;

    .close-btn {

      width: auto;
      height: auto;
      top: 20px;
      display: flex;
      align-items: center;
    }
  }
}