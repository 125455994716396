.product-categories-container {

  position: relative;
  margin: 0 -30px;

  &:before {
    content: '';
    background-color: $grey-bg;
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 0;
    width: 100%;
  }

  @media(min-width: $min-tablet) {
    margin: 35px;
  }
}

.product-categories-text {
  text-align: center;

  h2 {
    
  }
}

.product-categories {

  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;

  @media(min-width: $min-tablet) {
    padding: 0 30px;
  }

  .product-category-wrapper {

    text-decoration: none;
    padding: 10px;
    width: 100%;
    z-index: 1;

    .product-category {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 220px;
      position: relative;
      padding: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        background-color: rgba(0, 0, 0, .16);
      }

      .product-category-link {
        
        z-index: 1;
      }
    }

  }

  @media(min-width: $min-tablet) {
    .product-category-wrapper {

      .product-category {
        height: 310px;
      }
    }
  }

  @media(min-width: $min-desktop) {
    .product-category-wrapper {


      &.category-image-col-2 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    
      &.category-image-col-1 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  @media(min-width: $small-desktop) {
    .product-category-wrapper {

      .product-category-link {
        width: auto;
      }

      &.category-image-col-2 {
        flex: 0 0 66%;
        max-width: 66%;
      }
    
      &.category-image-col-1 {
        flex: 0 0 33%;
        max-width: 33%;
      }
    }
  }

}

a.img-wrapper {
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }
}

#Listings section {
  margin: 60px 0 40px;
}