.search-results-wrapper {
  .search-result {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border;

    h3 {
      font-weight: 500;
      margin-bottom: 0;

      a.underline {
        font-family: 'HelveticaNeueLTStd-Lt';
      }
    }
    p {
      margin-top: 20px;
    }
  }

  .search-no-results {

    margin: 45px 0;

    .button {
      max-width: 270px;
      width: 100%;
      margin-top: 40px;
      white-space: nowrap;

      svg {
        margin-right: 10px;
      }
    }
  }

  @media(min-width: $min-desktop) {
    .search-result {
      margin-top: 50px;
      padding-bottom: 50px;
    }
  }
}