.colour-grid {
  position: relative;

  &:before {
    content: '';
    background-color: #f5f5f5;
    position: absolute;
    top: 120px;
    bottom: 0;
    left: -30px;
    right: -30px;          
    z-index: 1;

    @media(min-width: $small-desktop) {
             left: -60px;
    right: -60px; 
                    }
  }

  @media(min-width: $max-tablet) {
    h4 {
      margin-left: 10px;
    }
  }

  h4.collaboration {
      margin-bottom: 40px;

      img {
        max-height: 46px; 
        width: auto;
        margin-bottom: 2px;
      }
    }

  .disclaimer {
    @include font-size(12, 22);
    position: relative;
    z-index: 2;
    padding: 10px 10px 40px;
    font-family: 'HelveticaNeueLTStd-It';
  }
 
	.colours {
		display: flex;
		flex-wrap: wrap;
    position: relative;
    /*justify-content: center;*/

		.swatch {
			width: 100%;
					margin: 10px 0;
      padding: 20px;
      display: flex;
      flex-direction: column;
      height: 170px;
      z-index: 2;
      position: relative;
      color: white;
      border: 1px #dedede solid;

      @media (min-width: $max-tablet) {
         margin: 10px;
         width: calc(33% - 20px);
            
      }

     @media (min-width: $small-desktop) {
        max-width: 265px;
        width: calc(25% - 20px);
        margin: 10px;
      }

      h4 {
        margin-bottom: 0px;
        color: white;
        margin-left: 0;
      }

      .ral {
         @include font-size(13);
         text-transform: uppercase;
         letter-spacing: 2px;
      }

      .zoom-link {
        position: relative;
        margin: auto 0 0;
        align-self: flex-start;
        color: white;
        
      }
		}
	}
}