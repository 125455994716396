// Widths
$column: 70;
$margin: 15;
$max-width: (1170 + $margin*2);

//1380 wide with 15px margin means $column = 85

// Breakpoints (these area defaults)
// Change per project

$tiny: 420px;
$small: 568px;
$medium: 768px;
$med-large: 860px; 
$large: 980px;
$huge: 1200px;
$mega: 1400px;



$base-font-size: 16;
$max-desktop: 1200px; // this is the same as $max-width, but it includes px unit.
$small-desktop: 980px;
$min-desktop: 769px;
$max-tablet: 768px;
$min-tablet: 569px;
$max-mobile: 568px;
$max-lg-desktop: 1366px;

$max-mobile-nav: 768px; // you can use this if your mobile nav will look completely different to your desktop nav and you'd like to separate that sass


$brown: #AD6B4E;
$green: #235D37;
$blue: #13294B;

// Colours

$white: #FFF;
$grey-1: #DDD;
$grey-2: #AAA;
$black: #000;

$black-1: #1d1d1b;
$black-2: #333333;
$black-3: #393939;

$grey-bg: #f5f5f5;
$black-bg: #171717;

$form-border: #707070;
$form-text: #171717;

$text: #333333;

$border: #dedede;
$dark-caption-line: #737373;
$light-caption-text: #f5f5f5;
$light-caption-line: #dedede;

$grey-button-hover: #dedede;
$dark-grey-button-hover: #434343;

$light-grey: #ececec;
$table-grey: #dedede;
$grey-on-black: #afafaf;
$grey-on-white: #757575;
$grey-on-overlay: #d0d0d0;
$brown-grey: #707070;

$overlay: rgba(0, 0, 0, .4);

$red: #dd1515;
$palered: #f3dfdf;
$form-error-bg: rgba(221, 21, 21, .1);

$notificationbg-green: #e9f3e0;
$notificationtxt-green: #56a616;
$notificationbg-grey: #dedede;
$notificationtxt-grey: #333333;
$notificationbg-red: #fbe7e7;


.gradient-overlay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--backgrounds-background-1-black)), linear-gradient(to bottom, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16));
}